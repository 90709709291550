import React, { useContext, useEffect, useState } from "react";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Grid, Tab, Tabs, TextField } from '@mui/material';
import { usersService } from '../../services/users-service';
import { SnackbarContext } from "../../context/snackbar-context";
import { useTranslation } from "react-i18next";
// user log details component
export default function UserLogDetails(props) {
   // to get the translation texts
  const { t } = useTranslation("General");
  const handleDialogClose = () => {
    props.setOpenDialog(false);
    //setUserLogDetails({});
  };

    // Snackbar - success and error messages
    const { showSnackbar } = useContext(SnackbarContext);
 // state variable to store user log details
    const [userLogDetails, setUserLogDetails] = useState({});
    
// get user log details by id
useEffect(() => { 
  const getUserLogDetailsById = () => {
    // call API to get user log details by id
    usersService.getUserLogDetailsById(props.selectedUserLog).then((response) => {   
      setUserLogDetails(response.result || {});   
    }, (error) => {
      console.log("Locations Details Error", error);
      showSnackbar(error.message.title, error.message.content, 'error');
    });
  };
  // call function to get user log details by id
  if(props.selectedUserLog){
    getUserLogDetailsById();
  }
  
},[props.selectedUserLog]);
  return (
    <>

      <Dialog
        open={props.openDialog}
        fullWidth={true}
        maxWidth={'lg'}
        PaperProps={{
          style: {
            maxHeight: '100%',
            borderRadius: '0px',
            p: 0
          }
        }}
      >
        <DialogTitle sx={{
          padding: '0px', borderBottom: 1, borderColor: 'divider'
        }}>
         
          <Grid item  sx={{padding: '10px 30px', lineHeight: '40px', backgroundColor: 'secondary3.main'}}>
            <Typography variant="heading3" color='secondary.main'>{userLogDetails.dateTime && (<React.Fragment>{userLogDetails.dateTime.formattedDate} {userLogDetails.dateTime.formattedTime}</React.Fragment> )}
            </Typography>
          <IconButton color="secondary4"
            aria-label="close"
            onClick={handleDialogClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8
            }}
          >
            <CloseRoundedIcon />
          </IconButton>
          </Grid>
        </DialogTitle>
        <Box className="logDetailsHead">
        <Grid container
          justifyContent="space-between"
          alignItems="center"  sx={{ backgroundColor: 'secondary3.main', px: 4, py: 2, opacity: '0.5' }}>

              <Grid item md container direction="column" >
              <Typography variant="heading1" color={'secondary'} >{t('Date & Time')}</Typography>
              </Grid>
              <Grid item md container direction="column" >
              <Typography variant="heading1" color={'secondary'} >{t('Action')}</Typography>
              </Grid>
              <Grid item md container direction="column" >
              <Typography variant="heading1" color={'secondary'} >{t('Device')}</Typography>
              </Grid>
              <Grid item md container direction="column" >
              <Typography variant="heading1" color={'secondary'} >{t('Execution Time')}</Typography>
              </Grid>
              <Grid item md container direction="column" >
              <Typography variant="heading1" color={'secondary'} >{t('Status Code')}</Typography>
              </Grid>
        </Grid>
        <Grid container
          justifyContent="space-between"
          alignItems="center" sx={{  px: 4, py: 2}}>
              <Grid item md container direction="column" >
              <Typography color={'secondary'} >{userLogDetails.dateTime && (<React.Fragment>{userLogDetails.dateTime.formattedDate} {userLogDetails.dateTime.formattedTime}</React.Fragment> )}
              </Typography>
              </Grid>
              <Grid item md container direction="column" >
              <Typography  color={'secondary'} >{userLogDetails.action}</Typography>
              </Grid>
              <Grid item md container direction="column" >
              <Typography  color={'secondary'} >{userLogDetails.deviceId}</Typography>
              </Grid>
              <Grid item md container direction="column" >
              <Typography  color={'secondary'} >{userLogDetails.executionTime}</Typography>
              </Grid>
              <Grid item md container direction="column" >
              <Typography  color={'secondary'} >{userLogDetails.statusCode ? userLogDetails.statusCode : '-' }</Typography>
              </Grid>
        </Grid>
        </Box>
        <DialogContent dividers sx={{ p: 4, borderTop:'0px' }} >
          <Box >
            <Grid container spacing={3}>
                <Grid item md container direction="column" >                  
                  <Typography variant="heading2" color={'secondary'} >{t('Device OS')}</Typography>
                  <TextField size="small" InputProps={{
                    readOnly: true
                  }} sx={{ mb: 2 }} value={userLogDetails.os}></TextField>
                  </Grid>
                  <Grid item md container direction="column" >                 
                  <Typography variant="heading2" color={'secondary'} >{t('OS Version')}</Typography>
                  <TextField size="small" InputProps={{
                    readOnly: true
                  }} sx={{ mb: 2 }} value={userLogDetails.osVersion}></TextField>

                  </Grid>
                  <Grid item md container direction="column" >                 
                  <Typography variant="heading2" color={'secondary'} >{t('App Version')}</Typography>
                  <TextField size="small" InputProps={{
                    readOnly: true
                  }} sx={{ mb: 2 }} value={userLogDetails.appVersion}></TextField>
                  </Grid>
                  </Grid>

                  <Grid container spacing={3}>
                <Grid item md container direction="column" >                 
                  <Typography variant="heading2" color={'secondary'} >{t('Firebase Tokens')}</Typography>
                  <TextField size="small" InputProps={{
                    readOnly: true
                  }} sx={{ mb: 2 }} value={userLogDetails.firebaseTokens}></TextField>
                  </Grid>
                  <Grid item md container direction="column" >                 
                  <Typography variant="heading2" color={'secondary'} >{t('Device Token')}</Typography>
                  <TextField size="small" InputProps={{
                    readOnly: true
                  }} sx={{ mb: 2 }} value={userLogDetails.deviceToken}></TextField>
                  </Grid>
                  <Grid item md container direction="column" >                 
                  <Typography variant="heading2" color={'secondary'} >{t('Device Last Seen')}</Typography>
                  <TextField size="small" InputProps={{
                    readOnly: true
                  }} sx={{ mb: 2 }} value={userLogDetails.lastSeen && userLogDetails.lastSeen.formattedDate +" " +  userLogDetails.lastSeen.formattedTime} ></TextField>
                  
                  </Grid>
                  </Grid>

                  <Grid container spacing={3}>
                <Grid item md container direction="column" >                 
                  <Typography variant="heading2" color={'secondary'} >{t('URL')}</Typography>
                  <TextField size="small" InputProps={{
                    readOnly: true
                  }} sx={{ mb: 2 }} value={userLogDetails.url}></TextField>

                  </Grid>
                  <Grid item md container direction="column" >
                  
                  <Typography variant="heading2" color={'secondary'} >{t('Method')}</Typography>
                  <TextField size="small" InputProps={{
                    readOnly: true
                  }} sx={{ mb: 2 }} value={userLogDetails.method}></TextField>

                  </Grid>
                  <Grid item md container direction="column" >

                 
                  </Grid>
                  
                  </Grid>

              <Grid container spacing={2}>
                <Grid item md container direction="column" >                  

                  <Typography variant="heading2" color={'secondary'} >{t('Request Body')}</Typography>
                  <TextField size="small" InputProps={{
                    readOnly: true,
                  }} sx={{ mb: 2 }} value={userLogDetails.requestBody}></TextField>
                  <Typography variant="heading2" color={'secondary'} >{t('Request Headers')}</Typography>
                  <TextField size="small" InputProps={{
                    readOnly: true,
                  }} sx={{ mb: 2 }} value={userLogDetails.requestHeaders}></TextField>
                  <Typography variant="heading2" color={'secondary'} >{t('Response Body')}</Typography>
                  <TextField size="small" InputProps={{
                    readOnly: true,
                  }} sx={{ mb: 2 }} value={userLogDetails.responseBody}></TextField>
                  <Typography variant="heading2" color={'secondary'} >{t('Exception')}</Typography>
                  <TextField size="small" InputProps={{
                    readOnly: true,
                  }} sx={{ mb: 2 }} value={userLogDetails.exception}></TextField>
                  
                  <Typography variant="heading2" color={'secondary'}>{t('Service Log')}</Typography>
                  <TextField size="small" multiline rows={4} InputProps={{
                    readOnly: true,
                  }} sx={{ mb: 2 }} value={userLogDetails.serviceLog}></TextField>
                </Grid>
              </Grid>
            

          </Box>
                </DialogContent>

      </Dialog>
    </>
  );
}