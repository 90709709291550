import React, { useContext, useEffect, useState } from "react";
import { Box, Dialog, DialogTitle, DialogContent, Typography, Grid, Tab, Tabs, TextField } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import PropTypes from 'prop-types';
import { SnackbarContext } from "../../context/snackbar-context";
import { useTranslation } from 'react-i18next';
import UserEdit from "./user-edit";
import UserVehicles from './user-vehicles';
import UserParkingHistory from "./user-parking-history";
import UserInvoices from './user-invoices';
import UserVouchers from './user-vouchers';
import UserLog from './user-log';
import UserSubscription from "./user-subscription";
import { usersService } from '../../services/users-service';
import './users.css';
import { use } from "i18next";


export default function UserDetails(props) {
  // state for tab value
  const [tab, setTab] = useState(props.selectedTabID);
 // to get the translation texts
  const { t } = useTranslation("General");
  // Snackbar - success and error messages
  const { showSnackbar } = useContext(SnackbarContext);
  // state variable to store users list
  const [userInfoDetails, setUserInfoDetails] = useState([]);
  // state variable to store open dialog
  const [openDialog, setOpenDialog] = React.useState(false);
  // state variable to store selected user ID
  const [userId, setUserId] = React.useState(props.selectedUser);
  // state variable to store selected user status
  const [updatedSubscriptionType, setUpdatedSubscriptionType] = useState('');
  
  // Get User Details by ID
  const getUserDetailsById = () => {
    usersService.getUserDetailsById(props.selectedUser).then((response) => {
      setUserInfoDetails(response['result'] || {});
    }, (error) => {
      console.log("User Details Error", error);
      showSnackbar(error.message.title, error.message.content, 'error');
    });
  };
// UseEffect to get user details by ID
  useEffect(() => {
    setTab(props.selectedTabID)    
    if (props.selectedUser) {
      getUserDetailsById();
    }
  }, [props.selectedUser]);
  // Handle Dialog Open
  const handleClickOpen = () => {
    setOpenDialog(true);
  };
  // CLOSE DIALOG
  const handleDialogClose = () => {
    props.onClose();
    props.setOpenDialog(false);
    setTab(0);
  };
  /** Update the Subscription Type */
  const handleSubscriptionUpdate = (newSubscriptionType, updatedUserId) => {
    if (userInfoDetails.id === updatedUserId) {
    setUpdatedSubscriptionType(newSubscriptionType);
    setUserId(updatedUserId);
    }else{
      setUpdatedSubscriptionType(userInfoDetails.subscriptionActive.subscriptionType);
      setUserId(userInfoDetails.id);
    }
  };

  // function to handle tab change 
  const handleTabsChange = (event, newValue) => {
    setTab(newValue);
  };
// function to handle user details update reload 
  const onUpdateUserDetails = (updatedUserDetails) => {
    getUserDetailsById();

  }
  return (
    <>
      {/* User Tabs*/}
      <Dialog open={props.openDialog} fullWidth={true} maxWidth={'lg'} PaperProps={{ style: { maxHeight: '100%', height: '100%', borderRadius: '0px', p: 0 } }} className='userTabs' BackdropProps={{
        sx: {
          backgroundColor: '#11042199', // Customize the backdrop color and opacity
        },
      }}>
        <DialogTitle sx={{
          paddingBottom: '0px', borderBottom: 1, borderColor: 'divider'
        }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={tab} onChange={handleTabsChange} textColor="secondary"
              indicatorColor="secondary" variant="scrollable"
              scrollButtons
              allowScrollButtonsMobile>
              <Tab label={t('Details')} sx={{ fontWeight: 'bold' }} />
              <Tab label={t('Vehicles')} sx={{ fontWeight: 'bold' }} />
              <Tab label={t('Parking History')} sx={{ fontWeight: 'bold' }} />
              <Tab label={t('Invoices')} sx={{ fontWeight: 'bold' }} />
              <Tab label={t('Vouchers')} sx={{ fontWeight: 'bold' }} />
              <Tab label={t('User Log')} sx={{ fontWeight: 'bold' }} />
            </Tabs>
          </Box>
          {/* CLOSE Icon */}
          <IconButton color="secondary4" aria-label="close" onClick={handleDialogClose} sx={{ position: 'absolute', right: 8, top: 8 }} className="btn-close" >
            <CloseRoundedIcon />
          </IconButton>
        </DialogTitle>
        {/* User End*/}

        {/* User Details Header*/}
        <Grid container
          justifyContent="space-between"
          alignItems="center" color='secondary1' sx={{ backgroundColor: 'secondary3.main', px: 4, py: 2 }}>
          <Grid item sx={{display:'flex', flexWrap:'wrap'}}>
            <Box>
              <Typography variant="heading3" mr={1}>{t('User ID')}</Typography>
              <Typography variant="text3" mr={5}>{userInfoDetails.id}</Typography>
            </Box>
            <Box>
            <Typography variant="heading3" mr={1}>{t('Name')}</Typography>
            <Typography variant="text3" mr={5}>{userInfoDetails.firstname} {userInfoDetails.lastname}</Typography>
            </Box>
            <Box>
            <Typography variant="heading3" mr={1}>{t('Email')}</Typography>
            <Typography variant="text3" mr={5}>{userInfoDetails.emailaddress}</Typography>
            </Box>
          <Box sx={{display:'flex',flexWrap:'inherit'}}>
            <Typography variant="heading3" mr={1}>{t('Subscription')}</Typography>
            <Typography variant="text3" mr={1}>
              {userInfoDetails.id=== userId && updatedSubscriptionType ? updatedSubscriptionType :
               userInfoDetails.subscriptionActive && userInfoDetails.subscriptionActive.subscriptionType
              }</Typography>
            <Typography variant="text3" mr={5} type="submit" sx={{ textDecoration: 'Underline', cursor: 'pointer', color: '#0fa9a5', fontWeight: 'bold' }} onClick={handleClickOpen} >{t('Change Subscription')}</Typography>
            </Box>
          </Grid>
        </Grid>
        {/* User Details Header End*/}

        {/* User Subscription Dialog*/}
        <UserSubscription subscriptionVal={ userInfoDetails.subscriptionActive && userInfoDetails.subscriptionActive.subscriptionType} openDialog={openDialog} setOpenDialog={setOpenDialog} selectedUser={props.selectedUser} onSubscriptionUpdate={handleSubscriptionUpdate} />
        {/* User Subscription Dialog End*/}

        {/* Tabs Content  */}
        <DialogContent dividers sx={{ p: 4 }}>
          <Box >
            {/* User Edit  */}
            {tab === 0 && <UserEdit userId={props.selectedUser}  onUpdateUserDetails={onUpdateUserDetails}/>}
            {/* User Edit End  */}
            {/* User Vehicles  */}
            {tab === 1 && <UserVehicles userId={props.selectedUser} />}
            {/* User Vehicles End  */}
            {/* User Parking History  */}
            {tab === 2 && <UserParkingHistory userId={props.selectedUser} />}
            {/* User Parking History End  */}
            {/* User Invoices  */}
            {tab === 3 && <UserInvoices userId={props.selectedUser} />}
            {/* User Invoices End  */}
            {/* User Vouchers  */}
            {tab === 4 && <UserVouchers userId={props.selectedUser} />}
            {/* User Vouchers End  */}
            {/* User Log  */}
            {tab === 5 && <UserLog userId={props.selectedUser} />}
            {/* User Log End  */}
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}