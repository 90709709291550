import { Box, Button } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { usersService } from "../../services/users-service";
import { SnackbarContext } from "../../context/snackbar-context";
import LPCustomTable from "../../ui-components/lp-custom-table/lp-custom-table";
import Typography from '@mui/material/Typography';
import UserLogDetails from "./user-log-details";
import { useTranslation } from "react-i18next";


const UserLogs = (props) => {
  const { t } = useTranslation("General");
  
  // Snackbar - success and error messages
  const { showSnackbar } = useContext(SnackbarContext);
   
  const[userId, setUserID] = React.useState(props.userId);
  // state variable to store open and close dialog
  const [openDialog, setOpenDialog] = React.useState(false);

  // state variable to store user details 
  const [selectedUserLog,setSelectedUserLog] = useState(null);

  // state variable to store users list
  const [userLogsList, setUserLogsList] = useState([]);

  // state variable to store pagination page number
  const [page, setPage] = useState(1);
  // state variable to store search param
  const [searchParam, setSearchParam] = useState(null);

  // state variable to store pagination amount per page
  const [paginationAmountPerPage, setPaginationAmountPerPage] = useState(50);
  // state variable to store and validate more records availbel for next scroll to call endpoint
  const [hasMore, setHasMore] = useState(true);
  // loading state
  const [loading, setLoading] = useState(false);
  // columns to show in table
  const [columns, setColumns] = useState([
    { id: 'dateTime', label: 'Date & Time', minWidth: 100, align:'left', width:150, link:true },
    { id: 'action', label: 'Action', minWidth: 150, align:'left', width:300,  },
    { id: 'deviceId', label: 'Device', minWidth: 150 },
    { id: 'executionTime', label: 'Execution Time', minWidth: 100 },
    { id: 'statusCode', label: 'Status Code', minWidth: 100  },      
  ])

 
  /***
   * Get all Users list from API and set to state variable 
   * UsersList will be called on page load 
   * and on search Users 
   * */
  useEffect(() => {    
    let logParams = {
      PaginationPage: page,
      PaginationAmountPerPage: paginationAmountPerPage,
      
    }
    const getUserLogs = () => {
      setLoading(true);
      usersService.getUserLogs(userId, logParams).then((response) => {        
        const newData = response.result;
        if (newData.length > 0) {
          newData.forEach((userLog) => {
            userLog.deviceId = userLog.os+ ', '+ userLog.osVersion+', '+userLog.appVersion; 
            userLog.dateTime = userLog.dateTime['formattedDate'] + ' ' + userLog.dateTime['formattedTime'];
          });
          setUserLogsList((prevData) => [...prevData, ...newData]);         
        } else {
          setHasMore(false);
        }
        setLoading(false);        
        //props.setTotalUserLogs(response.pagination.totalResults);
      }, (error) => {
        setLoading(false);
        console.log("Log Error", error);
        showSnackbar(error.message.title, error.message.content, 'error');
      });
    };
    getUserLogs();
    
    // return () => {};
  }, [page]);

  //Query :: if search value not avialbe then wat need to send to api

  
  // update page number on scroll
  const updatePage = () => {
    setPage((prevPage) => prevPage + 1);
  }

  // on click of user row in table
  const onSelectUserLog = (userLogDetails) => {      
    setSelectedUserLog(userLogDetails.id);
    setOpenDialog(true);  
       
  }
  
 // Open dialog box to show users details on click of users details button 
 const handleClickOpen = () => {
     setOpenDialog(true);
};

  return (
    <>

<Box >
            <Box mb={2}>
            <Typography variant="heading1" color="secondary"> {t('User Log')}</Typography>
            </Box>
          </Box>
           <LPCustomTable columns={columns} data={userLogsList} onRowClick={onSelectUserLog} updatePage={updatePage} hasMore={hasMore} loading={loading} />
     
           <UserLogDetails openDialog={openDialog} setOpenDialog={setOpenDialog} selectedUserLog={selectedUserLog} />
      
     
    </>
  );
}

export default UserLogs;
