import { Box, Typography, Switch, FormControlLabel, Stack } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { usersService } from "../../services/users-service";
import { SnackbarContext } from "../../context/snackbar-context";
import LPCustomTable from "../../ui-components/lp-custom-table/lp-custom-table";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faUserSlash } from '@fortawesome/pro-regular-svg-icons';
import { useTranslation } from "react-i18next";
import UserDetails from "./user-details";
import UserBanUnban from "./user-ban-unban";

import './users.css';
const UsersList = (props) => {
  const { t } = useTranslation("General");
  // Snackbar - success and error messages
  const { showSnackbar } = useContext(SnackbarContext);

  // state variable to store open and close dialog
  const [openDialog, setOpenDialog] = React.useState(false);
 
  
  const [openDialogBanUser, setOpenDialogBanUser] = React.useState(false);

  // state variable to store user details 
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedUserStatus, setSelectedUserStatus] = useState(true);
  
  const [activeSession, setActiveSession] = useState(false);
  // state variable to store users list
  const [usersList, setUsersList] = useState([]);

  // state variable to store pagination page number
  const [page, setPage] = useState(1);
  // state variable to store search param
  const [searchParam, setSearchParam] = useState(null);

  // state variable to store pagination amount per page
  const [paginationAmountPerPage, setPaginationAmountPerPage] = useState(50);
  // state variable to store and validate more records availbel for next scroll to call endpoint
  const [hasMore, setHasMore] = useState(true);
  // loading state
  const [loading, setLoading] = useState(false);
  // columns to show in table 
  const [columns, setColumns] = useState([
    { id: 'isBanned', label: 'Act', minWidth: 100, align: 'center', width: 70, component: 'icon' },
    { id: 'id', label: 'ID', minWidth: 100, align: 'center', width: 70, },
    { id: 'emailaddress', label: 'Email', minWidth: 100, link: true, tabId: 0, },
    { id: 'phonenumber', label: 'Phone', minWidth: 100 },
    { id: 'address', label: 'Address', minWidth: 100 },
    { id: 'vehicles', label: 'Vehicles', minWidth: 100, link: true, tabId: 1 },
    { id: 'invoices', label: 'Invoices', minWidth: 100, link: true, tabId: 3 },
    { id: 'activeSessions', label: 'Active Session', minWidth: 100 },
    { id: 'actions', label: 'Actions', minWidth: 100, component: 'actions', link: true },
  ])

 // Swith button to show active session users
  const handleChangeActive = (event) => {
    const isChecked = event.target.checked;
    setActiveSession(event.target.checked);
    setUsersList([]);
    // getAllUsers();
  };
   
  // Get all Users list from API and set to state variable
  const getAllUsers = () => {
    //User Search params
    let userSearchparams = {
      PaginationPage: page,
      PaginationAmountPerPage: paginationAmountPerPage,
      SearchValue: searchParam || null,
      activeSessions: activeSession,
      SearchField: searchParam ? 'SearchIndex' : null
    }
    setLoading(true);
    // Call API to get all users list
    usersService.getAllUsers(userSearchparams).then((response) => {
      const newData = response.result;
      if (newData.length > 0) {
        newData.forEach((user) => {
          // Ban and Ban delete status icon
          user.isBanned = user.isBanned ? (
            <FontAwesomeIcon icon={faUserSlash} style={{ color: '#7F1818' }} status={true} />
          ) : (
            <FontAwesomeIcon icon={faUser} style={{ color: '#187F1B' }} status={false} />
          );
          user.address = user.address;
        });
        setUsersList((prevData) => [...prevData, ...newData]);        
        if (response.pagination.totalPages <= 1 || newData.length < paginationAmountPerPage) {
          setHasMore(false);
        } else {
          setHasMore(true);
        }
      } else {
        setHasMore(false);
      }
      setLoading(false);
      props.setTotalUsers(response.pagination.totalResults);
    }, (error) => {
      setLoading(false);
      console.log("Users Error", error);
      showSnackbar(error.message.title, error.message.content, 'error');
    });
  };

  useEffect(() => {
    //  setActiveSession(false);   
    getAllUsers();
  }, [page, searchParam, activeSession]);

  const [selectedTabId, setSelectedTabId] = useState(0);
  // Reload data after closing the dialog
  const closeDialog = () => {
    setOpenDialog(false);     
    setUsersList([]);
    getAllUsers(); // Reload data after closing the dialog     
    setSelectedUser(null);
    setSelectedTabId(0);
  };
 // useEffect to update the user list on search
  useEffect(() => {
    setUsersList([]);
    setPage(1);

    setSearchParam(props.searchUser || null)
    // return () => {};
  }, [props.searchUser]);
 // update page number on scroll
  const updatePage = () => {
    setPage((prevPage) => prevPage + 1);
  }
  // on click of user row in table
  const onSelectUser = (userDetails) => {
    setSelectedTabId(userDetails.column.tabId);
    setSelectedUser(userDetails.id);    
    setOpenDialog(true);
  }
// Action items to show in table
  const actionItems = [
    { id: 'edit', label: t('Edit')+' '+t('user')+' '+t('details') },
    { id: 'banuser', label: t('Ban / Unban')+' '+t('user') },
    { id: 'delete', label: t('Delete')+' '+t('user') },
  ];
// on click of action items
  const onRowAction = (actionData, rowData, userDetails) => {
    setSelectedUserStatus(rowData.isBanned.props.status);
    let emailObj = {
      "emailAddress": rowData.emailaddress,
    }
    // Delete user
    if (actionData.id === 'delete') {         
      usersService.userDelete(rowData.id).then((response) => {
        showSnackbar(response.message.title, response.message.content, 'success');
        setUsersList((prevData) => prevData.filter((user) => user.id !== rowData.id));
        //props.setTotalUsers(response.pagination.totalResults);
        props.setTotalUsers((prevCount) => prevCount - 1);
        setLoading(false);
      }, (error) => {
          console.log('Error:', error);
          showSnackbar(error.message.title, error.message.content, 'error');
        });
    }
    // Edit user
    if (actionData.id === 'edit') {
      setSelectedUser(rowData.id);
      setOpenDialog(true);
    }
   // Ban and Ban Delete user
    if (actionData.id === 'banuser') {
      setSelectedUser(rowData.id);
      setOpenDialogBanUser(true);
    }

  }
// Update ban and ban delete status icon
  const onUpdateStatus = (status) => {
    usersList.map((user) => {
      if (user.id === selectedUser) {
        user.isBanned = status ? (
          <FontAwesomeIcon icon={faUserSlash} style={{ color: '#7F1818' }} status={true} />
        ) : (
          <FontAwesomeIcon icon={faUser} style={{ color: '#187F1B' }} status={false} />
        );
      }
    }
    );
  }

  return (
    <>
    <Box className="userSearchBar">
      <Stack direction="row" spacing={1} alignItems="center" sx={{
        justifyContent: 'end', marginTop: '-50px', marginRight: '20px', marginBottom: '15px',
      }} >
        
        <Typography>{t('Active Session')}</Typography>
        <Switch color="secondary" checked={activeSession} onChange={handleChangeActive} />
      </Stack>
      </Box>
      <LPCustomTable columns={columns} data={usersList} actions={actionItems} type="stopParking" onRowClick={onSelectUser} onRowAction={onRowAction} updatePage={updatePage} hasMore={hasMore} loading={loading} />
      <UserDetails openDialog={openDialog} setOpenDialog={setOpenDialog} selectedUser={selectedUser} onClose={closeDialog} selectedTabID={selectedTabId}/>
      <UserBanUnban onUpdateStatus={onUpdateStatus} openDialog={openDialogBanUser} setOpenDialog={setOpenDialogBanUser} banStatus={selectedUserStatus} selectType={'User'} selectedRowId={selectedUser} onClose={closeDialog} />
    </>
  );
}

export default UsersList;
