// Googel map component to show the location of the selected location on the map with the help of google map api and react-google-maps/api library    
import React, { useState } from 'react';
// import { GoogleMap, LoadScript } from '@react-google-maps/api';
import { GoogleMap, InfoWindow, Marker, useJsApiLoader } from '@react-google-maps/api';
import { Typography } from '@mui/material';
// variables to set the width and height of the map
const containerStyle = {
  width: '100%',
  height: '100%'
};
// function to load the google map api
const GoogleMapComponent = (props) => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyBxKGhYGQJgD3dBqdizf05Z-Yg2c_IgxPo"
  })
  // locationPosition variable to set the latitude and longitude of the selected location
  const locationPosition = {
    lat: props.coordinates.latitude,//53.215937,
    lng: props.coordinates.longitude//6.565862
  };
  // map variable to set the map instance to null initially and then set the map instance to the map object when the map is loaded 
  const [map, setMap] = React.useState(null)
  const [zoomValue, setZoomValue] = React.useState(0);
  // function to set the map instance to the map object when the map is loaded 
  const onLoad = React.useCallback(function callback(map) {
    // This is just an example of getting and using the map instance!!! don't just blindly copy!
    const bounds = new window.google.maps.LatLngBounds(locationPosition);
    map.fitBounds(bounds);
    setMap(map);
    setTimeout(() => {
      setZoomValue(14);
    }, 100);
  }, [])
  // function to set the map instance to null when the map is unmounted 
  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])

  const [showInfoWindow, setShowInfoWindow] = useState(false);
  // function to set the selected location to true when the marker is clicked 
  const onMarkerClick = () => {
    setShowInfoWindow(true);
  };
  // function to set the selected location to false when the info window is closed 
  const handleInfoWindowClose = () => {
    setShowInfoWindow(false);
  };

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={locationPosition}
      zoom={zoomValue}
      onLoad={onLoad}
      onUnmount={onUnmount}
      disableDefaultUI={'true'}
    >
      { /* Child components, such as markers, info windows, etc. */}
      <Marker position={locationPosition} onClick={() => onMarkerClick()} icon={`${process.env.PUBLIC_URL}/assets/images/map-marker-pin.svg`}>

      </Marker>
      {showInfoWindow && <InfoWindow position={locationPosition} onCloseClick={() => handleInfoWindowClose()}>
        <>
          <Typography variant='heading3' >{props.locationName.street}, {props.locationName.streetNumber}, {props.locationName.city}</Typography>
        </>
      </InfoWindow>}

    </GoogleMap>
  ) : <></>
}

export default GoogleMapComponent;