import React, { useContext, useEffect, useState } from "react";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Grid, Tab, Tabs, TextField, Divider } from '@mui/material';
import { usersService } from '../../services/users-service';
import { SnackbarContext } from "../../context/snackbar-context";
import { useTranslation } from "react-i18next";

export default function UserParkingHistoryDetails(props) {
  // to get the translation texts
  const { t } = useTranslation("General");
  // Snackbar - success and error messages
  const { showSnackbar } = useContext(SnackbarContext);
  // State Varible to store parking details
  const [userParkingDetails, setUserParkingDetails] = useState({});
    
// Handle close dialog
const handleDialogClose = () => {
  props.setOpenDialog(false);
  //setUserLogDetails({});
};

useEffect(() => {
 // User parking history details
  const getUserParkingHistoryDetails = () => {
    usersService.getUserParkingHistoryDetails(props.userId, props.selectedUserParkingId ).then((response) => {   
      setUserParkingDetails(response.result || {}); 
    }, (error) => {
      console.log("Parking Session Details Error", error);
      showSnackbar(error.message.title, error.message.content, 'error');
    });
  };
  if(props.selectedUserParkingId){
    getUserParkingHistoryDetails();
  }
  // return () => {};
},[props.selectedUserParkingId]);


  return (
    <>

      <Dialog
        open={props.openDialog}
        fullWidth={true}
        maxWidth={'lg'}
        PaperProps={{
          style: {
            maxHeight: '100%',
            borderRadius: '0px',
            p: 0
          }
        }}
      >
        <DialogTitle sx={{
          padding: '0px', borderBottom: 1, borderColor: 'divider'
        }}>
         
          <Grid item  sx={{padding: '10px 30px', lineHeight: '40px', backgroundColor: 'secondary3.main'}}>
            <Typography variant="heading3" color='secondary.main'>{t('Parking Session Details')}</Typography>
          
          <IconButton color="secondary4"
            aria-label="close"
            onClick={handleDialogClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8
            }}
          >
            <CloseRoundedIcon />
          </IconButton>
          </Grid>
        </DialogTitle>
        <Box className="parkingDetailHead">
        <Grid container
          justifyContent="space-between"
          alignItems="center"  sx={{ backgroundColor: 'secondary3.main', px: 4, py: 2, opacity: '0.5' }}>

              <Grid item md container direction="column" >
              <Typography variant="heading1" color={'secondary'} >{t('Name')}</Typography>
              </Grid>
              <Grid item md container direction="column" >
              <Typography variant="heading1" color={'secondary'} >{t('Email')}</Typography>
              </Grid>
              <Grid item md container direction="column" >
              <Typography variant="heading1" color={'secondary'} >{t('Phone')}</Typography>
              </Grid>
              <Grid item md container direction="column" >
              <Typography variant="heading1" color={'secondary'} >{t('Gender')}</Typography>
              </Grid>
              <Grid item md container direction="column" >
              <Typography variant="heading1" color={'secondary'} >{t('License Plate')}</Typography>
              </Grid>
        </Grid>
        <Grid container
          justifyContent="space-between"
          alignItems="center" sx={{  px: 4, py: 2}}>

              <Grid item md container direction="column" >
              <Typography color={'secondary'} >{userParkingDetails.user && (<React.Fragment>{userParkingDetails.user.firstname} {userParkingDetails.user.lastname}</React.Fragment> )}
              
              </Typography>
              </Grid>
              <Grid item md container direction="column" >
              <Typography  color={'secondary'} >{userParkingDetails.user && (<React.Fragment>{userParkingDetails.user.emailaddress}</React.Fragment>)}</Typography>
              </Grid>
              <Grid item md container direction="column" >
              <Typography  color={'secondary'} >{userParkingDetails.user && (<React.Fragment>{userParkingDetails.user.phonenumber} </React.Fragment>)}</Typography>
              </Grid>
              <Grid item md container direction="column" >
              <Typography  color={'secondary'} >{userParkingDetails.user && (<React.Fragment>{userParkingDetails.user.gender} </React.Fragment>)}</Typography>
              </Grid>
              <Grid item md container direction="column" >
              <Typography  color={'secondary'} >{userParkingDetails.vehicle && (<React.Fragment>{userParkingDetails.vehicle.licenseplate} </React.Fragment>)}</Typography>
              </Grid>
        </Grid>
        </Box>
        <DialogContent dividers sx={{ p: 4, borderTop:'0px' }} >
          <Box >
            {/*User Details*/}
            <Grid container spacing={3}>
                <Grid item md container direction="column" >
                  <Typography variant="heading2" color={'secondary'} >{t('Address')}</Typography>
                  <TextField size="small" InputProps={{
                    readOnly: true
                  }} sx={{ mb: 2 }} value={userParkingDetails.user && userParkingDetails.user.address}></TextField>
                  </Grid>

                  <Grid item md container direction="column" >
                  <Typography variant="heading2" color={'secondary'} >{t('City')}</Typography>
                  <TextField size="small" InputProps={{
                    readOnly: true
                  }} sx={{ mb: 2 }} value={userParkingDetails.user && userParkingDetails.user.city} ></TextField>
                  </Grid>
                  <Grid item md container direction="column" >
                  <Typography variant="heading2" color={'secondary'} >{t('House Number')}</Typography>
                  <TextField size="small" InputProps={{
                    readOnly: true
                  }} sx={{ mb: 2 }} value={userParkingDetails.user && userParkingDetails.user.housenumber} ></TextField>
                  </Grid>
                  <Grid item md container direction="column" >
                  <Typography variant="heading2" color={'secondary'} >{t('Postal Code')}</Typography>
                  <TextField size="small" InputProps={{
                    readOnly: true
                  }} sx={{ mb: 2 }} value={userParkingDetails.user && userParkingDetails.user.postalCode}></TextField>
                  </Grid>
                  </Grid>

                  {/*User Details*/}
            <Grid container spacing={3}>
                <Grid item md container direction="column" >
                  <Typography variant="heading2" color={'secondary'} >{t('Start Date')}</Typography>
                  <TextField size="small" InputProps={{
                    readOnly: true
                  }} sx={{ mb: 2 }} value={userParkingDetails.startTime && userParkingDetails.startTime.formattedDate +" "+ userParkingDetails.startTime.formattedTime}></TextField>
                  </Grid>

                  <Grid item md container direction="column" >
                  <Typography variant="heading2" color={'secondary'} >{t('End Date')}</Typography>
                  <TextField size="small" InputProps={{
                    readOnly: true
                  }} sx={{ mb: 2 }} value={userParkingDetails.startTime && userParkingDetails.endTime.formattedDate +" "+ userParkingDetails.endTime.formattedTime} ></TextField>
                  </Grid>
                  <Grid item md container direction="column" >
                  <Typography variant="heading2" color={'secondary'} >{t('Duration')}</Typography>
                  <TextField size="small" InputProps={{
                    readOnly: true
                  }} sx={{ mb: 2 }} value={userParkingDetails.duration && userParkingDetails.duration.hours+':'+userParkingDetails.duration.minutes+':'+userParkingDetails.duration.seconds} ></TextField>
                  </Grid>
                  <Grid item md container direction="column" >
                  <Typography variant="heading2" color={'secondary'} >{t('Cost')}</Typography>
                  <TextField size="small" InputProps={{
                    readOnly: true
                  }} sx={{ mb: 2 }} value={userParkingDetails.costs && userParkingDetails.costs.totalIncludingVat}></TextField>
                  </Grid>
                  </Grid>
                  <Divider sx={{margin: '20px -30px'}}/>
                <Box>
                  <Box sx={{ mb: 2 }}>
                <Typography variant="heading1" color="secondary" > Parking Point </Typography>
                </Box>
                <Grid container spacing={3}>
                <Grid item md container direction="column" >
                  <Typography variant="heading2" color={'secondary'} >{t('Street Number')}</Typography>
                  <TextField size="small" InputProps={{
                    readOnly: true
                  }} sx={{ mb: 2 }} value={userParkingDetails.parkingPoint && userParkingDetails.parkingPoint.geometry.streetNumber }></TextField>
                  </Grid>

                  <Grid item md container direction="column" >
                  <Typography variant="heading2" color={'secondary'} >{t('Street')}</Typography>
                  <TextField size="small" InputProps={{
                    readOnly: true
                  }} sx={{ mb: 2 }} value={userParkingDetails.parkingPoint && userParkingDetails.parkingPoint.geometry.street} ></TextField>
                  </Grid>
                  <Grid item md container direction="column" >
                  <Typography variant="heading2" color={'secondary'} >{t('Province')}</Typography>
                  <TextField size="small" InputProps={{
                    readOnly: true
                  }} sx={{ mb: 2 }} value={userParkingDetails.parkingPoint && userParkingDetails.parkingPoint.geometry.province} ></TextField>
                  </Grid>
                  <Grid item md container direction="column" >
                  <Typography variant="heading2" color={'secondary'} >{t('Municipality')}</Typography>
                  <TextField size="small" InputProps={{
                    readOnly: true
                  }} sx={{ mb: 2 }} value={userParkingDetails.parkingPoint && userParkingDetails.parkingPoint.geometry.municipality}></TextField>
                  </Grid>
                  </Grid>

                  <Grid container spacing={3}>
                <Grid item md container direction="column" >
                  <Typography variant="heading2" color={'secondary'} >{t('City')}</Typography>
                  <TextField size="small" InputProps={{
                    readOnly: true
                  }} sx={{ mb: 2 }} value={userParkingDetails.parkingPoint && userParkingDetails.parkingPoint.geometry.city }></TextField>
                  </Grid>

                  <Grid item md container direction="column" >
                  <Typography variant="heading2" color={'secondary'} >{t('Country')}</Typography>
                  <TextField size="small" InputProps={{
                    readOnly: true
                  }} sx={{ mb: 2 }} value={userParkingDetails.parkingPoint && userParkingDetails.parkingPoint.geometry.country} ></TextField>
                  </Grid>
                  <Grid item md container direction="column" >
                  <Typography variant="heading2" color={'secondary'} >{t('Latitude')}</Typography>
                  <TextField size="small" InputProps={{
                    readOnly: true
                  }} sx={{ mb: 2 }} value={userParkingDetails.parkingPoint && userParkingDetails.parkingPoint.geometry.coordinate.latitude} ></TextField>
                  </Grid>
                  <Grid item md container direction="column" >
                  <Typography variant="heading2" color={'secondary'} >{t('Longitude')}</Typography>
                  <TextField size="small" InputProps={{
                    readOnly: true
                  }} sx={{ mb: 2 }} value={userParkingDetails.parkingPoint && userParkingDetails.parkingPoint.geometry.coordinate.longitude}></TextField>
                  </Grid>
                  </Grid>

                  <Grid container spacing={3}>
                <Grid item md container direction="column" >
                  <Typography variant="heading2" color={'secondary'} >{t('Description')}</Typography>
                  <TextField size="small" InputProps={{
                    readOnly: true
                  }} sx={{ mb: 2 }} value={userParkingDetails.parkingPoint && userParkingDetails.parkingPoint.description }></TextField>
                  </Grid>

                  <Grid item md container direction="column" >
                  <Typography variant="heading2" color={'secondary'} >{t('Revision')}</Typography>
                  <TextField size="small" InputProps={{
                    readOnly: true
                  }} sx={{ mb: 2 }} value={userParkingDetails.parkingPoint && userParkingDetails.parkingPoint.parkingPointRevision} ></TextField>
                  </Grid>
                  <Grid item md container direction="column" >
                  <Typography variant="heading2" color={'secondary'} >{t('Unique Id')}</Typography>
                  <TextField size="small" InputProps={{
                    readOnly: true
                  }} sx={{ mb: 2 }} value={userParkingDetails.parkingPoint && userParkingDetails.parkingPoint.uniqueId} ></TextField>
                  </Grid>
                  <Grid item md container direction="column" >
                  <Typography variant="heading2" color={'secondary'} >{t('Stop Action')}</Typography>
                  <TextField size="small" InputProps={{
                    readOnly: true
                  }} sx={{ mb: 2 }} value={userParkingDetails.parkingPoint && userParkingDetails.parkingPoint.stopAction}></TextField>
                  </Grid>
                  </Grid>
                </Box>

                <Divider sx={{margin: '20px -30px'}}/>
                <Box>
                  <Box sx={{ mb: 2 }}>
                <Typography variant="heading1" color="secondary" > Device Location At Start </Typography>
                </Box>
                <Grid container spacing={3}>
                <Grid item md container direction="column" >
                  <Typography variant="heading2" color={'secondary'} >{t('Street Number')}</Typography>
                  <TextField size="small" InputProps={{
                    readOnly: true
                  }} sx={{ mb: 2 }} value={userParkingDetails.deviceLocationAtStart && userParkingDetails.deviceLocationAtStart.streetNumber }></TextField>
                  </Grid>

                  <Grid item md container direction="column" >
                  <Typography variant="heading2" color={'secondary'} >{t('Street')}</Typography>
                  <TextField size="small" InputProps={{
                    readOnly: true
                  }} sx={{ mb: 2 }} value={userParkingDetails.deviceLocationAtStart && userParkingDetails.deviceLocationAtStart.street} ></TextField>
                  </Grid>
                  <Grid item md container direction="column" >
                  <Typography variant="heading2" color={'secondary'} >{t('Province')}</Typography>
                  <TextField size="small" InputProps={{
                    readOnly: true
                  }} sx={{ mb: 2 }} value={userParkingDetails.deviceLocationAtStart && userParkingDetails.deviceLocationAtStart.province} ></TextField>
                  </Grid>
                  <Grid item md container direction="column" >
                  <Typography variant="heading2" color={'secondary'} >{t('Municipality')}</Typography>
                  <TextField size="small" InputProps={{
                    readOnly: true
                  }} sx={{ mb: 2 }} value={userParkingDetails.deviceLocationAtStart && userParkingDetails.deviceLocationAtStart.municipality}></TextField>
                  </Grid>
                  </Grid>

                  <Grid container spacing={3}>
                <Grid item md container direction="column" >
                  <Typography variant="heading2" color={'secondary'} >{t('City')}</Typography>
                  <TextField size="small" InputProps={{
                    readOnly: true
                  }} sx={{ mb: 2 }} value={userParkingDetails.deviceLocationAtStart && userParkingDetails.deviceLocationAtStart.city }></TextField>
                  </Grid>

                  <Grid item md container direction="column" >
                  <Typography variant="heading2" color={'secondary'} >{t('Country')}</Typography>
                  <TextField size="small" InputProps={{
                    readOnly: true
                  }} sx={{ mb: 2 }} value={userParkingDetails.deviceLocationAtStart && userParkingDetails.deviceLocationAtStart.country} ></TextField>
                  </Grid>
                  <Grid item md container direction="column" >
                  <Typography variant="heading2" color={'secondary'} >{t('Latitude')}</Typography>
                  <TextField size="small" InputProps={{
                    readOnly: true
                  }} sx={{ mb: 2 }} value={userParkingDetails.deviceLocationAtStart && userParkingDetails.markerLocationAtStart.coordinate.latitude} ></TextField>
                  </Grid>
                  <Grid item md container direction="column" >
                  <Typography variant="heading2" color={'secondary'} >{t('Longitude')}</Typography>
                  <TextField size="small" InputProps={{
                    readOnly: true
                  }} sx={{ mb: 2 }} value={userParkingDetails.deviceLocationAtStart && userParkingDetails.deviceLocationAtStart.coordinate.longitude}></TextField>
                  </Grid>
                  </Grid>
                </Box>
                <Divider sx={{margin: '20px -30px'}}/>
                <Box>
                  <Box sx={{ mb: 2 }}>
                <Typography variant="heading1" color="secondary" > Marker Location At Start  </Typography>
                </Box>
                <Grid container spacing={3}>
                <Grid item md container direction="column" >
                  <Typography variant="heading2" color={'secondary'} >{t('Street Number')}</Typography>
                  <TextField size="small" InputProps={{
                    readOnly: true
                  }} sx={{ mb: 2 }} value={userParkingDetails.markerLocationAtStart && userParkingDetails.markerLocationAtStart.streetNumber }></TextField>
                  </Grid>

                  <Grid item md container direction="column" >
                  <Typography variant="heading2" color={'secondary'} >{t('Street')}</Typography>
                  <TextField size="small" InputProps={{
                    readOnly: true
                  }} sx={{ mb: 2 }} value={userParkingDetails.markerLocationAtStart && userParkingDetails.markerLocationAtStart.street} ></TextField>
                  </Grid>
                  <Grid item md container direction="column" >
                  <Typography variant="heading2" color={'secondary'} >{t('Province')}</Typography>
                  <TextField size="small" InputProps={{
                    readOnly: true
                  }} sx={{ mb: 2 }} value={userParkingDetails.markerLocationAtStart && userParkingDetails.markerLocationAtStart.province} ></TextField>
                  </Grid>
                  <Grid item md container direction="column" >
                  <Typography variant="heading2" color={'secondary'} >{t('Municipality')}</Typography>
                  <TextField size="small" InputProps={{
                    readOnly: true
                  }} sx={{ mb: 2 }} value={userParkingDetails.markerLocationAtStart && userParkingDetails.markerLocationAtStart.municipality}></TextField>
                  </Grid>
                  </Grid>

                  <Grid container spacing={3}>
                <Grid item md container direction="column" >
                  <Typography variant="heading2" color={'secondary'} >{t('City')}</Typography>
                  <TextField size="small" InputProps={{
                    readOnly: true
                  }} sx={{ mb: 2 }} value={userParkingDetails.deviceLocationAtStart && userParkingDetails.markerLocationAtStart.city }></TextField>
                  </Grid>

                  <Grid item md container direction="column" >
                  <Typography variant="heading2" color={'secondary'} >{t('Country')}</Typography>
                  <TextField size="small" InputProps={{
                    readOnly: true
                  }} sx={{ mb: 2 }} value={userParkingDetails.markerLocationAtStart && userParkingDetails.markerLocationAtStart.country} ></TextField>
                  </Grid>
                  <Grid item md container direction="column" >
                  <Typography variant="heading2" color={'secondary'} >{t('Latitude')}</Typography>
                  <TextField size="small" InputProps={{
                    readOnly: true
                  }} sx={{ mb: 2 }} value={userParkingDetails.markerLocationAtStart && userParkingDetails.markerLocationAtStart.coordinate.latitude} ></TextField>
                  </Grid>
                  <Grid item md container direction="column" >
                  <Typography variant="heading2" color={'secondary'} >{t('Longitude')}</Typography>
                  <TextField size="small" InputProps={{
                    readOnly: true
                  }} sx={{ mb: 2 }} value={userParkingDetails.markerLocationAtStart && userParkingDetails.markerLocationAtStart.coordinate.longitude}></TextField>
                  </Grid>
                  </Grid>
                </Box>
            

          </Box>
                </DialogContent>

      </Dialog>
    </>
  );
}