// Lazyloading lp Table Actions Component to display the actions in the table rows and handle the click events of the actions 
import React from 'react';
import { MenuItem, Menu, Button, Typography, } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { userSessionService } from '../../services/user-session-service';

const ActionsDropdown = (props) => {
    // to get the translation texts
    const { t } = useTranslation("General");
    // state for anchor element
    const [anchorEl, setAnchorEl] = React.useState(null);
    // state for open and close of the actions
    const open = Boolean(anchorEl);
    // Logged in user details state
    const logedInUserInfo = userSessionService.getUserInfo() || {};

    // function to handle click event of the actions
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    // function to handle close event of the actions
    const handleClose = () => {
        setAnchorEl(null);
    };
    // function to handle click event of the actions
    const onActionClick = (actionData) => {
        props.onActionClicks(actionData, props.rowData);
        setAnchorEl(null);
    }
    //console.log(props.rowData.status+ "rowData in actions" );
    return (
        <>
        {
            props.rowData.status === 'Finished' ? 
            <Typography textTransform={'none'}  fontSize={'0.875rem'}>{t('No Actions')}</Typography>
            :
            <Button sx={{ 'justifyContent': 'left', px: 0 }} variant='text'
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
            >
                <Typography textTransform={'none'} onClick={handleClick} fontSize={'0.875rem'}>{t('Actions')}</Typography>
                <ArrowDropDownIcon />

            </Button>
            }
            <Menu
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}>
                    { 
                    props.actions.map((action, index) => (
                        action.type ==='vehicleHistory' ? 
                        props.rowData.status === 'InProgress' ? 
                    <MenuItem onClick={() => onActionClick(action)} key={index} >{action.label } </MenuItem>: null
                    :                     
                    action.status === 'show' ? 
                    <MenuItem onClick={() => onActionClick(action)} key={index} >{action.label}</MenuItem> 
                     : action.status === 'edit' ? props.rowData.usedByUsers===0 && <MenuItem onClick={() => onActionClick(action)} key={index} >{action.label}</MenuItem>
                     : action.status === 'view' ? props.rowData.usedByUsers>0 && <MenuItem onClick={() => onActionClick(action)} key={index} >{action.label}</MenuItem>
                    : props.rowData.id !== logedInUserInfo.id && <MenuItem onClick={() => onActionClick(action)} key={index} >{action.label}</MenuItem>
                ))}
            </Menu>
        </>
    )
}

export default ActionsDropdown;