// Promotions List Component - To show all promotions list and their details and actions to edit, delete and update status 
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, MenuItem, Select, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import PromotionDetailsDialog from "./promotion-details-dialog";
import { promotionsService } from "../../services/promotions-service";
import { SnackbarContext } from "../../context/snackbar-context";
import LPCustomTable from "../../ui-components/lp-custom-table/lp-custom-table";
import { useTranslation } from "react-i18next";

const statusList = [
  { id: 'Active', name: 'Active' },
  { id: 'Inactive', name: 'Inactive' }
];

const PromotionsList = (props) => {
  // to get the translation texts
  const { t } = useTranslation("General");
  // Snackbar - success and error messages
  const { showSnackbar } = useContext(SnackbarContext);
  // state variable to store open and close dialog
  const [openDialog, setOpenDialog] = React.useState(false);
  // state variable to store Promotion details 
  const [selectedPromotion, setSelectedPromotion] = useState({});
  // state variable to store promotions list
  const [promotionsList, setPromotionsList] = useState([]);
  // state variable to store pagination page number
  const [page, setPage] = useState(1);
  // state variable to store pagination amount per page
  let paginationAmountPerPage = 50;
  // state variable to store has more data to load or not 
  const [hasMore, setHasMore] = useState(true);
  // state variable to store loading status 
  const [loading, setLoading] = useState(false);
  // state for create promotion dialog box
  const [openStatusDialog, setOpenStatusDialog] = React.useState(false);
  // state variable to store selected promotion status
  const [selectedStatus, setSelectedStatus] = useState('');
  // State variable to store action type 
  const [actionType, setActionType] = useState('');

  // Columns to show in Promotions list table
  const columns = [
    { id: 'name', label: 'Promotion', minWidth: 100, link: true },
    { id: 'status', label: 'Status', minWidth: 100 },
    { id: 'startDate', label: 'Start Date', minWidth: 100 },
    { id: 'endDate', label: 'End Date', minWidth: 100 },
    { id: 'usedByUsers', label: 'Used', minWidth: 100 },
    { id: 'actions', label: 'Actions', minWidth: 100, component: 'actions' }
  ];
  // Actions to perform on Promotions list table 
  const actionItems = [
    { id: 'edit', label: t('Edit'), status: 'edit' },
    { id: 'edit', label: t('View'), status: 'view' },
    { id: 'delete', label: t('Delete'), status: 'edit' },
    { id: 'status', label: t('Update')+' '+t('Status'), status: 'show' }
  ];

  // Get all Promotions list from API and set to state variable PromotionsList will be called on page load 
  useEffect(() => {
    let promotionSearchParams = {
      PaginationPage: page,
      PaginationAmountPerPage: paginationAmountPerPage
    }
    // Get all Promotions list from API and set to state variable PromotionsList will be called on page load 
    const getAllPromotions = () => {
      setLoading(true);
      promotionsService.getAllPromotions(promotionSearchParams).then((response) => {
        const newData = response.result;
        if (newData.length > 0) {
          newData.forEach((promotion) => {
            promotion.startDate = promotion.startDate ? promotion.startDate['formattedDate'] : ''// promotion.startDate ? promotion.startDate['formattedDate'] + ' ' + promotion.startDate['formattedTime'] : '';
            promotion.endDate = promotion.endDate ? promotion.endDate['formattedDate'] : '' // promotion.endDate ? promotion.endDate['formattedDate'] + ' ' + promotion.endDate['formattedTime'] : '';
          });
          setPromotionsList((prevData) => [...prevData, ...newData]);
          if (response.pagination.totalPages <= 1 || newData.length < paginationAmountPerPage) {
            setHasMore(false);
          } else {
            setHasMore(true);
          }

        } else {
          setHasMore(false);
        }
        setLoading(false);
      }, (error) => {
        setLoading(false);
        console.log("Promotions Error", error);
        showSnackbar(error.message.title, error.message.content, 'error');
      });
    };
    getAllPromotions();
    // return () => {};
  }, [page]);
  // To update page number on scroll to load more data 
  const updatePage = () => {
    setPage((prevPage) => prevPage + 1);
  }
  // to set selected promotion details to state variable and open dialog box when click on promotion details button 
  const onSelectPromotion = (promotionDetails) => {
    setSelectedPromotion({ ...promotionDetails });
    setOpenDialog(true);
  }
  // Api call to delete promotion and remove from list 
  const deletePromotion = (promotionId) => {
    promotionsService.deletePromotion(promotionId).then((response) => {
      showSnackbar(response.message.title, response.message.content, 'success');
      // remove from list 
      const newList = promotionsList.filter((promotion) => promotion.id !== promotionId);
      setPromotionsList(newList);
    }, (error) => {
      console.log("deletePromotion error", error);
      showSnackbar(error.message.title, error.message.content, 'error');
    }
    );
  }
  // Api call to update promotion status and update in list 
  const updateStatus = (statusType) => {
    if (statusType) {
      promotionsService.updatePromotionStatus(selectedPromotion.id, { promotionStatus: statusType }).then((response) => {
        showSnackbar('Update Promotion', 'Promotion updated Successfully', 'success');
        const newList = promotionsList.filter((promotion) => {
          if (promotion.id === selectedPromotion.id) {
            promotion.status = statusType;
          }
          return promotion;
        });
        setPromotionsList(newList);
        setOpenStatusDialog(false);
        setSelectedPromotion({ id: null });
      }, (error) => {
        console.log("Update Promotion Error", error);
        setSelectedPromotion({ id: null });
        setOpenStatusDialog(false);
        showSnackbar(error.message.title, error.message.content, 'error');
      });
    }
  };

  // To perform action on click of action button in table 
  const onRowAction = (actionData, rowData) => {
    if (actionData.id === 'delete') {
      setActionType('delete');
      deletePromotion(rowData.id)
    } else if (actionData.id === 'status') {
      setSelectedStatus(rowData.status);
      setSelectedPromotion(rowData);
      setActionType('status');
      setOpenStatusDialog(true);
    } else if (actionData.id === 'edit') {
      setActionType('edit');
      setSelectedPromotion(rowData);
      setOpenDialog(true);
    }
  }

  // close dialog on close click 
  const onClosePromotionDialog = () => {
    setOpenDialog(false);
    setSelectedPromotion({ id: null });
    setActionType('');
  }

  // open dialog on create promotion click 
  const onCreatePromotionClick = () => {
    setActionType('create');
    setSelectedPromotion({ id: null });
    setOpenDialog(true);
  }

  // create promotion based on promotion details
  const onCreatePromotion = (newPromotionDetails) => {
    if (newPromotionDetails) {
      newPromotionDetails.usedByUsers=newPromotionDetails.totalAmountOfTimesUsed || 0;
      newPromotionDetails.startDate=newPromotionDetails.startDate.formattedDate;
      newPromotionDetails.endDate=newPromotionDetails.endDate.formattedDate;
      let newList = promotionsList;
      newList.push(newPromotionDetails);
      setPromotionsList(newList);
    }
  }
  // update promotion based on promotion details
  const onUpdatePromotion = (promotionDetails) => {
    promotionDetails.usedByUsers=promotionDetails.totalAmountOfTimesUsed || 0;
    promotionDetails.startDate=promotionDetails.startDate.formattedDate;
    promotionDetails.endDate=promotionDetails.endDate.formattedDate;
    let newList = promotionsList.map((promotion) => {
      if (promotion.id === promotionDetails.id)promotion = promotionDetails;
      return promotion;
    }
    );
    setPromotionsList(newList);
  }

  return (
    <>
      <Grid container
        justifyContent="space-between"
        alignItems="center" my={3} >
        <Grid item xs="auto">
          <Typography variant="heading1" color="secondary">{t('Promotions')}</Typography>
        </Grid>
        <Grid item>
          <Button variant="contained" color="primary" onClick={onCreatePromotionClick}>{t('Create Promo')}</Button>
        </Grid>
      </Grid>
      <Grid container>
        <LPCustomTable columns={columns} data={promotionsList} onRowClick={onSelectPromotion} actions={actionItems} onRowAction={onRowAction} updatePage={updatePage} hasMore={hasMore} loading={loading} />
      </Grid>
      <PromotionDetailsDialog openDialog={openDialog} data={selectedPromotion.id} onCreatePromotion={onCreatePromotion} onUpdatePromotion={onUpdatePromotion} actionType={actionType} onClosePromotionDialog={onClosePromotionDialog} />
      {actionType === 'status' && <Dialog fullWidth={true} maxWidth={'xs'} open={openStatusDialog} >
        <DialogTitle>{t('Update')+' '+('Status')} </DialogTitle>
        <DialogContent>
          <Select fullWidth={true} value={selectedStatus} onChange={(e) => updateStatus(e.target.value)} >
            {statusList.map((status, index) =>
              <MenuItem key={index} value={status.id} >{status.name}</MenuItem>
            )}
          </Select>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenStatusDialog(false)}>{t('Close')}</Button>
        </DialogActions>
      </Dialog>
      }
    </>
  );
}

export default PromotionsList;