import { Box, FormControl, Grid, InputAdornment, OutlinedInput, Typography, Button, DialogActions, DialogContent, Dialog, DialogTitle } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { usersService } from "../../services/users-service";
import { SnackbarContext } from "../../context/snackbar-context";
import LPCustomTable from "../../ui-components/lp-custom-table/lp-custom-table";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGarage } from '@fortawesome/pro-solid-svg-icons';
import {faUser, faUserSlash } from '@fortawesome/pro-regular-svg-icons';
import { useTranslation } from "react-i18next";
import SearchIcon from '@mui/icons-material/Search';
import UserParkingHistoryDetails from "./user-parking-history-details";
import UserParkingStop from "./user-parking-stop";
import './users.css';

const UserParkingHistory = (props) => {
  // to get the translation texts  
  const { t } = useTranslation("General");
  // state variable to store search query
  const [searchQuery, setSearchQuery] = useState('');
  // Snackbar - success and error messages
  const { showSnackbar } = useContext(SnackbarContext);
  // state variable to store user ID
  const[userId, setUserID] = React.useState(props.userId);
  // state variable to store open and close dialog
  const [openDialog, setOpenDialog] = React.useState(false);
  const [ppenDialogStopPrking, setOpenDialogStopPrking] = React.useState(false);
  // state variable to store users list
  const [userParkingList, setUserParkingList] = useState([]);  
  // state variable to store pagination page number
  const [page, setPage] = useState(1);
  // state variable to store search param
  const [searchParam, setSearchParam] = useState(null);
  // state variable to store parking details 
  const [selectedUserParkingId, setSelectedUserParkingId] = useState(null);
  // state variable to store Stop parking ID
  const [selectedStopParkingId, setSelectedStopParkingId] = useState(null);
  // state variable to store open and close dialog
  const [openDialogStopParking, setOpenDialogStopParking] = useState(null);  
  // state variable to store pagination amount per page
  const [paginationAmountPerPage, setPaginationAmountPerPage] = useState(50);
  // state variable to store and validate more records availbel for next scroll to call endpoint
  const [hasMore, setHasMore] = useState(true);
  // loading state
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const EuroSymbol = '\u20AC';
  // columns to show in table
  const [columns, setColumns] = useState([
    { id: 'parkingPoint', label: 'Name', minWidth: 100, align:'left', width:100, link:true },
    { id: 'startDateTime', label: 'Start Time', minWidth: 150, align:'left', width:150,  },
    { id: 'endDateTime', label: 'End Time', minWidth: 150, align:'left', width:150,  },
    { id: 'duration', label: 'Duration', minWidth: 150, align:'left', width:150,  },    
    { id: 'vehicle', label: 'Licence Plate', minWidth: 150},   
    { id: 'costs', label: 'Amount', minWidth: 150},  
    { id: 'status', label: 'Status', minWidth: 150, displayProp:"hide"},  
    { id: 'actions', label: 'Actions', minWidth: 100 , component:'actions', link:true},  
  ])
// Actions to show in table
  const actionItems = [
    {id: 'stop' , label: t('Stop Parking'), status: 'show', type:'vehicleHistory'},  
  ]
 
  /***
   * Get all Users list from API and set to state variable 
   * UsersList will be called on page load 
   * and on search Users 
   * */
// Query :: if search value not avialbe then wat need to send to api
  let parkingSearchparams = {      
    PaginationPage: page,
    PaginationAmountPerPage: paginationAmountPerPage,
    SearchValue: searchParam || null,
    SearchField: searchParam ? 'SearchIndex' : null
  }
// Get all Parking History list from API and set to state variable
  const getUserParkingHistory = () => {
    setLoading(true);
    // call API to get all Parking History list
    usersService.getUserParkingHistory(props.userId, parkingSearchparams).then((response) => {
      const newData = response.result;
      if (newData.length > 0) {
        newData.forEach((parking) => {           
          parking.parkingPoint = parking.parkingPoint['nprId'];            
          parking.duration = parking.duration['hours']+'hours '+parking.duration['minutes']+'min '+parking.duration['seconds']+ "sec";
          parking.vehicle = parking.vehicle['licenseplate'];
          parking.costs = `${EuroSymbol} ${parking.costs['transactionCostsIncludingVat']}`;
          parking.startDateTime = parking.startDateTime['formattedDate'] + ' ' + parking.startDateTime['formattedTime'];
          parking.endDateTime = parking.endDateTime['formattedDate'] + ' ' + parking.endDateTime['formattedTime'] ;
          
        });
        if (searchParam) {
          setUserParkingList(newData);
        } else {
          setUserParkingList((prevData) => [...prevData, ...newData]);
        }       
        if(response.pagination.totalPages<=1 || newData.length<paginationAmountPerPage){
          setHasMore(false);
        }
      } else {
        if (searchParam) {
          setUserParkingList(newData);
        } else {
          setUserParkingList((prevData) => [...prevData, ...newData]);
        }
        setHasMore(false);
      }
      setLoading(false);
      //props.setTotalUserLogs(response.pagination.totalResults);
    }, (error) => {
      setLoading(false);
      console.log("Users Error", error);
      showSnackbar(error.message.title, error.message.content, 'error');
    });
  };

  useEffect(() => {
    getUserParkingHistory();
  }, [page, searchParam]);

  useEffect(() => {
    if (!searchQuery) {
      // Clear the data when search term is empty
      setUserParkingList([]);
      setHasMore(false)
    }
    setLoading(true);
    const searchTimer = setTimeout(() => {
      // setLocationsList([]);
      setPage(1);
      setSearchParam(searchQuery || null)
      setHasMore(true)
    }, 500);
    // To clear the timeout when the searchQuery changes
    return () => clearTimeout(searchTimer);
  }, [searchQuery]);
  // update page number on scroll
  const updatePage = () => {
    setPage((prevPage) => prevPage + 1);
  }
  // on click of Parking row in table
  const onSelectParkingHistory = (UserParkingHistoryDetails) => { 
    setSelectedUserParkingId(UserParkingHistoryDetails.id);
    setTimeout(() => {
      setOpenDialog(true);
    },10);        
  }
  const onRowAction = (actionData, rowData, userDetails) => {
    if(actionData.id == 'stop'){
      setSelectedStopParkingId(rowData.id); 
        setOpenDialogStopParking(true);     
    };     
  }
  
  const onUpdateStatus = () => {
    setUserParkingList([]);
    getUserParkingHistory();
   }

  return (
    <>
    <Box mb={2}>
            <Typography variant="heading1" color="secondary"> {t('Parking Details')} </Typography>
            </Box>
          <Grid container color='secondary1' sx={{ marginBottom: '20px', display: 'flex' }}>
            <FormControl sx={{ m: 1, width: 400 }} variant="outlined" size="small" color="secondary">
            <OutlinedInput value={searchQuery}
              onChange={(event) => {
                setSearchQuery(event.target.value);
              }}
              startAdornment={<InputAdornment position="start"> <SearchIcon color="secondary" /></InputAdornment>}
              placeholder={t('Search')}
            />
            </FormControl>
          </Grid>
           <LPCustomTable columns={columns} data={userParkingList}  onRowClick={onSelectParkingHistory} actions={actionItems}  updatePage={updatePage} hasMore={hasMore} loading={loading}  onRowAction={onRowAction}/>
     
           <UserParkingHistoryDetails openDialog={openDialog} setOpenDialog={setOpenDialog} selectedUserParkingId={selectedUserParkingId} userId={userId}/>
            
           <UserParkingStop openDialog={openDialogStopParking} setOpenDialog={setOpenDialogStopParking} userId={userId} onSelectParkingHistory={onSelectParkingHistory}  selectedStopParkingId={selectedStopParkingId} onUpdateStatus={onUpdateStatus}/>
           
      
     
    </>
  );
}

export default UserParkingHistory;
