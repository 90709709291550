import { Box, Button } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { usersService } from "../../services/users-service";
import { SnackbarContext } from "../../context/snackbar-context";
import LPCustomTable from "../../ui-components/lp-custom-table/lp-custom-table";
import Typography from '@mui/material/Typography';
import UserLogDetails from "./user-log-details";
import { t } from "i18next";


const UserInvoices = (props) => {
  
  
  // Snackbar - success and error messages
  const { showSnackbar } = useContext(SnackbarContext);
   
  const[userId, setUserID] = React.useState(props.userId);
  // state variable to store open and close dialog
  const [openDialog, setOpenDialog] = React.useState(false);

  // state variable to store user details 
  const [selectedUserInvoices,setSelectedUserInvoices] = useState(null);

  // state variable to store users list
  const [userInvoicesList, setUserInvoicesList] = useState([]);

  // state variable to store pagination page number
  const [page, setPage] = useState(1);
  // state variable to store search param
  const [searchParam, setSearchParam] = useState(null);
 // state for search bar to handle search value change 
    const [searchQuery, setSearchQuery] = useState('');
  // state variable to store pagination amount per page
  const [paginationAmountPerPage, setPaginationAmountPerPage] = useState(50);
  // state variable to store and validate more records availbel for next scroll to call endpoint
  const [hasMore, setHasMore] = useState(true);
  // loading state
  const [loading, setLoading] = useState(false);
  // columns to show in table
  // Euro symbol
  const EuroSymbol = '\u20AC';
  const [columns, setColumns] = useState([
    { id: 'invoiceId', label: 'Invoice Number', minWidth: 100, align:'left', width:150 },
    { id: 'period', label: 'Period', minWidth: 150, align:'left', width:150,  },    
    { id: 'totalCostsIncludingVat', label: 'Total', minWidth: 150 },
    { id: 'paymentStatus', label: 'Payment Status', minWidth: 150  },   
    { id: 'actions', label: 'Actions', minWidth: 100, component: 'actions', link: true },   
  ])

  // Action items to show in table
  const actionItems = [
    { id: 'emailInvoice', label: t('Email invoice'),status: 'show',},
    { id: 'downloadInvoice', label: t('Download invoice'),status: 'show',},
    { id: 'viewInBuckaroo', label: t('View in Buckaroo'),status: 'show',}
  ];
 
  /***
   * Get all Users list from API and set to state variable 
   * UsersList will be called on page load 
   * and on search Users 
   * */
  useEffect(() => {
    let userSearchparams = {
      PaginationPage: page,
      PaginationAmountPerPage: paginationAmountPerPage,
      
    }
    
    const getUserInvoices = () => {
      setLoading(true);
      usersService.getUserInvoices(userId).then((response) => {
        // setUsersList(response.result);
        // console.log("getAllUsers Success", response.result);
        const newData = response.result;
        if (newData.length > 0) {
          newData.forEach((userInvoices) => {  
            userInvoices.totalCostsIncludingVat = `${EuroSymbol} ${userInvoices.totalCostsIncludingVat}`;
          });
          
            if (response.pagination.totalPages <= 1 || newData.length < paginationAmountPerPage) {
              setUserInvoicesList(newData);
              
              setHasMore(false);
            } else {               
              setUserInvoicesList((prevData) => [...prevData, ...newData]);             
              setHasMore(true);
              
            }            
           
            
        } else {
          
          setHasMore(false);
        }
        setLoading(false);
        
        //props.setTotalUserLogs(response.pagination.totalResults);
      }, (error) => {
        setLoading(false);
        console.log("Users Error", error);
        showSnackbar(error.message.title, error.message.content, 'error');
      });
    };
    getUserInvoices();
    
    // return () => {};
  }, [page]);

  //Query :: if search value not avialbe then wat need to send to api

    // useEffect to handle search bar value change by setting a timer to wait for 500ms before updating the searchLocation state to avoid unnecessary re-rendering of the locations list component 
    useEffect(() => {
      if (!searchQuery) {
        // Clear the data when search term is empty
        setUserInvoicesList([]);
        setHasMore(false)
      }
      setLoading(true);
      const searchTimer = setTimeout(() => {
        // setLocationsList([]);
        setPage(1);
        setSearchParam(searchQuery || null)
        setHasMore(true)
      }, 500);
      // To clear the timeout when the searchQuery changes
      return () => clearTimeout(searchTimer);
    }, [searchQuery]);

 

  // update page number on scroll
  const updatePage = () => {
    setPage((prevPage) => prevPage + 1);
  }

  const onRowAction = (actionData, rowData) => {
    //console.log('actionData', rowData);
    
    if (actionData.id === 'emailInvoice') {
      usersService.emailInvoices(userId, rowData.id).then((response) => {
        showSnackbar(response.message.title, response.message.content, 'success');         //setLoading(false);
        //const newData = response.result;
        //window.open(newData.invoiceUrl, '_blank');
      }, (error) => {
        
          console.log('Error:', error);
          showSnackbar(error.message.title, error.message.content, 'error');
        });
    }
    /*if (actionData.id === 'downloadInvoice') {
      usersService.downloadUserInvoices(userId, rowData.id).then((response) => {
        //showSnackbar(response.message.title, response.message.content, 'success');         //setLoading(false);
        // Create a hidden anchor element
        const newData = response.result;
    const link = document.createElement('a');
    link.href = newData.invoiceUrl;
    link.target = '_blank'; // Open the PDF in a new tab
    link.download = newData.invoiceUrl; // Specify the desired filename    
    // Trigger a click event on the anchor element
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
      }, (error) => {
        
          console.log('Error:', error);
          showSnackbar(error.message.title, error.message.content, 'error');
        });
    }*/

    if (actionData.id === 'downloadInvoice') {
      usersService.downloadUserInvoices(userId, rowData.id).then((response) => {
        //showSnackbar(response.message.title, response.message.content, 'success');         //setLoading(false);
        const newData = response.result;
        // = newData.invoiceUrl;
        
              window.open(newData.invoiceUrl, '_blank');
          
      }, (error) => {
        
          console.log('Error:', error);
          showSnackbar(error.message.title, error.message.content, 'error');
        });
    }

    if (actionData.id === 'viewInBuckaroo') {
      window.open(rowData.buckarooUrl, '_blank');
    }
  }
  // on click of user row in table
  /*const onSelectUserLog = (userLogDetails) => {  
    //alert(userLogDetails.id+'LogID');     
    setSelectedUserLog(userLogDetails.id);
    setOpenDialog(true);  
       
  }*/
  
 // Open dialog box to show users details on click of users details button 
 const handleClickOpen = () => {
     setOpenDialog(true);
};

  return (
    <>

<Box >
            <Box mb={2}>
            <Typography variant="heading1" color="secondary"> {t('Invoices')} </Typography>
            </Box>
          </Box>
           <LPCustomTable columns={columns} actions={actionItems} data={userInvoicesList}  updatePage={updatePage} hasMore={hasMore} loading={loading} onRowAction={onRowAction} />
     
           {/*<UserLogDetails openDialog={openDialog} setOpenDialog={setOpenDialog} selectedUserLog={selectedUserLog} />*/}
      
     
    </>
  );
}

export default UserInvoices;
