// Location Details component - To display location details in a dialog box on click of a location card in the locations list page 
import React, { useContext, useEffect, useState } from "react";
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { CircularProgress, Grid, Tab, Tabs, TextField } from '@mui/material';

import { useTranslation } from "react-i18next";
import GoogleMapComponent from "../google-map";

import { SnackbarContext } from "../../context/snackbar-context";
import { locationsService } from '../../services/locations-service';

const LocationDetails = (props) => {
  // to get the translation texts
  const { t } = useTranslation("General");
  // Snackbar - success and error messages
  const { showSnackbar } = useContext(SnackbarContext);
  // State to store location details
  const [locationDetails, setLocationDetails] = useState({});
  // Close Dialog box on click of close icon button and reset the location details state
  const handleDialogClose = () => {
    props.setOpenDialog(false);
    props.setSelectedLocation(null);
    setLocationDetails({});
  };

  // Get location details by id on component load and on change of selected location id 
  useEffect(() => {
    const getLocationDetailsById = () => {
      locationsService.getParkingpointbyId(props.selectedLocation).then((response) => {
        response.result.lastUpdated = response.result.lastUpdated ? [response.result.lastUpdated['formattedDate'], response.result.lastUpdated['formattedTime']].join(' ') : '';
        setLocationDetails(response.result || {});
      }, (error) => {
        console.log("Locations Details Error", error);
        showSnackbar(error.message.title, error.message.content, 'error');
      });
    };
    if (props.selectedLocation) {
      getLocationDetailsById();
    }

    // return () => {};
  }, [props.selectedLocation]);

  return (
    <>
      <Dialog
        open={props.openDialog}
        fullWidth={true}
        maxWidth={'lg'}
        PaperProps={{
          style: {
            maxHeight: '100%',
            borderRadius: '0px',
            p: 0
          }
        }}
      >
        <DialogTitle sx={{
          paddingBottom: '0px', borderBottom: 1, borderColor: 'divider'
        }}>

          <Tabs textColor="secondary"
            indicatorColor="secondary" value={0} aria-label="basic tabs example">
            <Tab label={t('Overview')} id="overview-tab"
              aria-controls="overview-tabpanel" />
          </Tabs>
          <IconButton color="secondary4"
            aria-label="close"
            onClick={handleDialogClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8
            }}
          >
            <CloseRoundedIcon />
          </IconButton>
        </DialogTitle>

        <Grid container
          justifyContent="space-between"
          alignItems="center" sx={{ backgroundColor: 'secondary3.main', px: 4, py: 2 }}>

          <Grid item >
            <Typography variant="heading3" color='secondary.main'>{locationDetails.nprId}</Typography>
          </Grid>

          <Grid item>
            <Grid container
              direction="row"
              xs={{ justifyContent: { xs: 'flex-start', sm: 'flex-end' } }}
              // justifyContent="flex-end"
              alignItems="center">
              <Grid item>
                <Typography variant="heading3" mr={2} color='secondary.main'>{t('Last Updated')}</Typography>
                <Typography variant="text3" mr={5} color='gray1.main'> {locationDetails.lastUpdated || ''}</Typography>
                {/* <Typography variant="text3" mr={5} color='gray1.main'> {locationDetails.lastUpdated ? locationDetails.lastUpdated.formattedDate + ' ' + locationDetails.lastUpdated.formattedTime : ''}</Typography> */}
              </Grid>
              <Grid item >
                <Typography variant="heading3" mr={2} color='secondary.main'>{t('Version')}</Typography>
                <Typography variant="text3" color='gray1.main'>{locationDetails.revision || 0}</Typography>
              </Grid>
            </Grid>
          </Grid>

        </Grid>

        <DialogContent dividers sx={{ p: 4 }} >
          <Box >
            <Box mb={2}>
              <Typography variant="heading1" color={'secondary'} >{t('Overview')}</Typography>
            </Box>
            <form >

              <Grid container spacing={2}>
                <Grid item md container direction="column" >

                  {/* Unique Id */}
                  <Typography variant="heading2" color={'secondary'} >{t('Unique ID')}</Typography>
                  <TextField size="small" sx={{ mb: 2 }} value={locationDetails.uniqueId || ''}></TextField>


                  {/* NPR ID */}
                  <Typography variant="heading2" color={'secondary'} >{t('NPR ID')}</Typography>
                  <TextField size="small" InputProps={{
                    readOnly: true,
                  }} sx={{ mb: 2 }} value={locationDetails.nprId || ''}></TextField>


                  {/* Description */}
                  <Typography variant="heading2" color={'secondary'}>{t('Description')}</Typography>
                  <TextField size="small" multiline rows={4} InputProps={{
                    readOnly: true,
                  }} sx={{ mb: 2 }} value={locationDetails.description || ''}></TextField>
                  {/* Area */}
                  <Typography variant='heading2' color={'secondary'} >{t('Area')}</Typography>
                  <Grid container direction="column"
                    sx={{ border: '1px solid #0000003b', borderRadius: '4px', px: 2.5, pt: 1.5 }} >

                    <Grid item>
                      <Grid container justifyContent="space-between"
                        alignItems="center">
                        <Typography variant="text1" mb={1.5}>{t('Area Manager')}</Typography>
                        <Typography variant="text2" mb={1.5} color="gray1.main" >{locationDetails.areaMangerId || ''}</Typography>
                      </Grid>
                    </Grid>

                    <Grid item >
                      <Grid container justifyContent="space-between"
                        alignItems="center">
                        <Typography variant="text1" mb={1.5}>{t('Area')}</Typography>
                        <Typography variant="text2" color="gray1.main" mb={1.5}>{locationDetails.areaId || ''}</Typography>
                      </Grid>
                    </Grid>

                    <Grid item >
                      <Grid container justifyContent="space-between"
                        alignItems="center">
                        <Typography variant="text1" mb={1.5}>{t('Start Date Area')}</Typography>
                        <Typography variant="text2" color="gray1.main" mb={1.5}>{locationDetails.startDateArea && locationDetails.startDateArea.formattedDate || ''}</Typography>
                      </Grid>
                    </Grid>

                    <Grid item >
                      <Grid container justifyContent="space-between"
                        alignItems="center">
                        <Typography variant="text1" mb={1.5}>{t('End Date Area')}</Typography>
                        <Typography variant="text2" color="gray1.main" mb={1.5}>{locationDetails.endDateArea && locationDetails.endDateArea.formattedDate || ''}</Typography>
                      </Grid>
                    </Grid>

                    {/* Wrap text */}
                    <Grid item >
                      <Grid container justifyContent="space-between" alignItems="flex-start">
                        <Grid item>
                          <Typography variant="text1" >{t('Area Description')}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} justifyContent="flex-end">
                          <Typography sx={{ textAlign: { xs: "left", sm: "right" }, mt: { xs: 1, sm: 0 } }} component='div' variant="text2" color="gray1.main" >{locationDetails.areaDescription || ''}</Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item md container direction="column" >
                  {/* Map */}
                  <Typography variant="heading2" color={'secondary'}>{t('Map')}</Typography>
                  <Box sx={{ backgroundColor: 'secondary1.main', height: 300 }} mb={2} textAlign="center">
                    {locationDetails.coordinate ? <GoogleMapComponent coordinates={locationDetails.coordinate} locationName={locationDetails} /> : <CircularProgress size={25} color="secondary" />}
                  </Box>

                  {/* Address */}
                  <Typography variant='heading2' color={'secondary'}>{t('Address')}</Typography>
                  <Grid container direction="column"
                    sx={{ border: '1px solid #0000003b', borderRadius: '4px', px: 2.5, py: 1.5 }} mb={2}>

                    <Grid item>
                      <Grid container justifyContent="space-between"
                        alignItems="center">
                        <Typography variant="text1" mb={1.5}>{t('Street')}</Typography>
                        <Typography variant="text2" mb={1.5} color="gray1.main" >{locationDetails.street || ''}</Typography>
                      </Grid>
                    </Grid>

                    <Grid item >
                      <Grid container justifyContent="space-between"
                        alignItems="center">
                        <Typography variant="text1" mb={1.5}>{t('Number')}</Typography>
                        <Typography variant="text2" color="gray1.main" mb={1.5}>{locationDetails.streetNumber || ''}</Typography>
                      </Grid>
                    </Grid>

                    <Grid item >
                      <Grid container justifyContent="space-between"
                        alignItems="center">
                        <Typography variant="text1" mb={1.5}>{t('City')}</Typography>
                        <Typography variant="text2" color="gray1.main" mb={1.5}>{locationDetails.city || ''}</Typography>
                      </Grid>
                    </Grid>

                    <Grid item >
                      <Grid container justifyContent="space-between"
                        alignItems="center">
                        <Typography variant="text1" mb={1.5}>{t('Municipality')}</Typography>
                        <Typography variant="text2" color="gray1.main" mb={1.5}>{locationDetails.municipality || ''}</Typography>
                      </Grid>
                    </Grid>

                    <Grid item >
                      <Grid container justifyContent="space-between"
                        alignItems="center">
                        <Typography variant="text1" mb={1.5}>{t('Province')}</Typography>
                        <Typography variant="text2" color="gray1.main" mb={1.5}>{locationDetails.province || ''}</Typography>
                      </Grid>
                    </Grid>

                    <Grid item >
                      <Grid container justifyContent="space-between"
                        alignItems="center">
                        <Typography variant="text1" >{t('Country')}</Typography>
                        <Typography variant="text2" color="gray1.main" >{locationDetails.country || ''}</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}
export default LocationDetails;