// common service for all http requests using axios library and interceptors for token refresh and error handling 
import axios from "axios";
import { userSessionService } from "../user-session-service";

class HttpService {
  // Base URL and default options for all requests 
  apiBaseURL = process.env.REACT_APP_API_URL;
  options = {
    // redirect: 'follow',
    method: "GET",
    headers: {
      "Accept": "application/json",
      "Content-Type": "application/json"
    },
  };

  isRefreshing = false;
  failedQueue = [];
  processQueue(error, token = null) {
    // console.log("PROCESS QUEUE", this.failedQueue);
    this.failedQueue.forEach(prom => {
      if (error) {
        prom.reject(error);
      } else {
        prom.resolve(token);
      }
    });

    this.failedQueue = [];
  };

  constructor() {
    // Add a request interceptor
    axios.interceptors.request.use(
      (request) => {
        const userSession = userSessionService.getUserSession()
        if (userSession && userSession.token) {
          // console.log("AXIOS INTERCEPTORS REQUEST TOKEN exists", request);
          request.headers['Authorization'] = 'Bearer ' + userSession.token
        }
        // console.log("AXIOS INTERCEPTORS REQUEST AFTER", request);
        return request;
      },
      (error) => {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        return Promise.reject(error);
      }
    );

    // Add a response interceptor for Refresh Token
    axios.interceptors.response.use(
      (response) => response,
      async (error) => {
        const originalRequest = error.config;
        console.log("AXIOS INTERCEPTORS RESPONSE ERROR", error);
        // console.log("AXIOS INTERCEPTORS RESPONSE ERROR originalRequest", originalRequest);
        //if (!originalRequest.url.includes('/authenticate/administrator') && error.response.status === 401 && !originalRequest._retry) {
        if (!originalRequest.url.includes('/authenticate/administrator') 
        || (originalRequest.method=='delete' && originalRequest.url.includes('/authenticate/administrator') )) {

          if ((error.response.status === 401 || error.response.status === 412) && !originalRequest._retry) {

              if (this.isRefreshing) {
              return new Promise((resolve, reject) => {
                this.failedQueue.push({ resolve, reject, url:originalRequest.url });
              }).then((token) => {
                originalRequest.headers['Authorization'] = 'Bearer ' + token;
                return axios(originalRequest);
              }).catch(err => {
                return Promise.reject(err);
              });
            }

            originalRequest._retry = true;
            this.isRefreshing = true;

            return new Promise((resolve, reject) => {
              this.refreshAccessToken().then((response) => {
                console.log("REFRESH SUCCESS", response);
                userSessionService.setUserSession(response.result);
                const userSession = userSessionService.getUserSession()
                const newAccessToken = userSession.token;
                axios.defaults.headers.common['Authorization'] = `Bearer ${newAccessToken}`;
                originalRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
                this.processQueue(null, newAccessToken);
                resolve(axios(originalRequest));
              }).catch(refreshError => {
                this.processQueue(refreshError, null);
                // Handle refresh token error
                console.log("REFRESH ERROR", refreshError);
                // userSessionService.removeUserSession();
                window.location.href = "/login";
                reject(refreshError);
              }).then(() => {
                this.isRefreshing = false;
              });
            });


            // //////////////////////////////////////////////////////////////////////////////
            //   this.isRefreshing = true;
            //   originalRequest._retry = true;
            //   try {
            //     const response = await this.refreshAccessToken();
            //     userSessionService.setUserSession(response.result);
            //     const userSession = userSessionService.getUserSession();
            //     const newAccessToken = userSession.token;
            //     // axios.defaults.headers.common['Authorization'] = `Bearer ${newAccessToken}`;
            //     originalRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
            //     // this.processQueue(null, newAccessToken);
            //     return axios(originalRequest);
            //   } catch (refreshError) {
            //     // this.processQueue(refreshError, null);
            //     // Handle refresh token error
            //     console.log("REFRESH ERROR", refreshError);
            //     userSessionService.removeUserSession();
            //     window.location.href = "/login";
            //   } finally { this.isRefreshing = false; }
            //   //////////////////////////////////////////////////////////////////////////////

          } // 401 412 if ends
        } // if ends
        return Promise.reject(error);
      }); // Add a response interceptor for Refresh Token ends
  } // constructor ends

  // Refresh Token API Call 
  refreshAccessToken = () => {
    const userSession = userSessionService.getUserSession();
    return this.patch('/authenticate/administrator',
      { refreshToken: userSession.refreshToken }, null,
      { authentication: userSession.token })
  };
  // GET Method 
  get(url, queryParams, headers = null) {
    if (headers) {
      headers = { ...this.options.headers, ...headers };
    }
    let postPromise = new Promise((resolve, reject) => {
      axios.get(this.apiBaseURL + url, {
        headers: headers,
        params: queryParams
      })
        .then((response) => {
          console.log("AXIOS GET Success Response", response);
          resolve(response.data);
        },
          (error) => {
            // response.status
            // response.statusText
            console.log("AXIOS GET Faliure Response", error);
            if (error.response.status >= 400 && error.response.status < 500) {
              reject(error.response.data);
            } else {
              let message = {
                title: "Server Error",
                content: "Please try again later"
              }
              error.response.data = { message };
              reject(error.response.data);
            }

          })
        .catch(error => {
          // response.status
          // response.statusText
          console.log("AXIOS GET Error Response", error);
          reject(error);
        });
    });
    return postPromise;
  }
  // POST Method
  post(url, data, queyParams = null, headers = null) {
    if (headers) {
      headers = { ...this.options.headers, ...headers };
    }
    let postPromise = new Promise((resolve, reject) => {
      axios.post(this.apiBaseURL + url, data, {
        headers: headers
      })
        .then((response) => {
          console.log("AXIOS POST Success Response", response);
          resolve(response.data);
        },
          (error) => {
            console.log("AXIOS POST Faliure Response", error);
            if (error.response.status >= 400 && error.response.status < 500) {
              reject(error.response.data);
            } else {
              let message = {
                title: "Server Error",
                content: "Please try again later"
              }
              error.response.data = { message };
              reject(error.response.data);
            }
          })
        .catch(error => {
          console.log("AXIOS POST Error Response", error);
          reject(error);
        });
    });
    return postPromise;
  }
  // Patch Method 
  patch(url, data, queyParams = null, headers = null) {
    if (headers) {
      headers = { ...this.options.headers, ...headers };
    }
    let postPromise = new Promise((resolve, reject) => {
      axios.patch(this.apiBaseURL + url, data, {
        headers: headers,
      })
        .then((response) => {
          console.log("AXIOS PATCH Success Response", response);
          resolve(response.data);
        },
          (error) => {
            console.log("AXIOS PATCH Faliure Response", error);
            if (error.response.status >= 400 && error.response.status < 500) {
              reject(error.response.data);
            } else {
              let message = {
                title: "Server Error",
                content: "Please try again later"
              }
              error.response.data = { message };
              reject(error.response.data);
            }
          })
        .catch(error => {
          console.log("AXIOS PATCH Error Response", error);
          reject(error);
        });
    });
    return postPromise;
  }
  // Put Method 
  put(url, data, queyParams = null, headers = null) {
    if (headers) {
      headers = { ...this.options.headers, ...headers };
    }
    let postPromise = new Promise((resolve, reject) => {
      axios.put(this.apiBaseURL + url, data, {
        headers: headers,
      })
        .then((response) => {
          console.log("AXIOS PUT Success Response", response);
          resolve(response.data);
        },
          (error) => {
            console.log("AXIOS PUT Faliure Response", error);
            if (error.response.status >= 400 && error.response.status < 500) {
              reject(error.response.data);
            } else {
              let message = {
                title: "Server Error",
                content: "Please try again later"
              }
              error.response.data = { message };
              reject(error.response.data);
            }
          })
        .catch(error => {
          console.log("AXIOS PUT Error Response", error);
          reject(error);

        });
    });
    return postPromise;
  }
  // Delete Method
  delete(url, data, queryParams, headers = null) {
    if (headers) {
      headers = { ...this.options.headers, ...headers };
    }
    let postPromise = new Promise((resolve, reject) => {
      axios.delete(this.apiBaseURL + url, { data: data }, {
        headers: headers,
        params: queryParams,
      })
        .then((response) => {
          console.log("AXIOS DELETE Success Response", response);
          resolve(response.data);
        },
          (error) => {
            console.log("AXIOS DELETE Faliure Response", error);
            if (error.response.status >= 400 && error.response.status < 500) {
              reject(error.response.data);
            } else {
              let message = {
                title: "Server Error",
                content: "Please try again later"
              }
              error.response.data = { message };
              reject(error.response.data);
            }
          })
        .catch(error => {
          console.log("AXIOS DELETE Error Response", error);
          reject(error);
        });
    });
    return postPromise;
  }


}
export const httpService = new HttpService();
