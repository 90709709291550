import React, { useContext, useEffect, useState } from "react";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import Box from '@mui/material/Box';
import { Grid, Tab, Tabs, TextField,  FormControl, InputLabel, MenuItem, Select, Button, FormHelperText, IconButton, Typography, Dialog,DialogActions, DialogContent, DialogTitle  } from '@mui/material';
import { usersService } from '../../services/users-service';
import { SnackbarContext } from "../../context/snackbar-context";
import { useTranslation } from "react-i18next";
import { faLampStreet } from "@fortawesome/pro-solid-svg-icons";
import UsersList from "./users-list";

export default function UserBanUnban(props) {
   // to get the translation texts  
  const { t } = useTranslation("General");
  //error message   
  const [errors, setErrors] = useState({});
  //selected user ID
  const [userId, setUserId] = useState(props.selectedUser);
  //selected user status
  const [userStatus, setUserStatus] = useState(props.selectedUserStatus);  
   // Snackbar - success and error messages
   const { showSnackbar } = useContext(SnackbarContext);
   //loading
   const [loading, setLoading] = useState(false);  
    //reason type
   const [reasonType, setReasonType] = useState('SelectReason');
   //reason note
   const [reasonNote, setReasonNote] = useState('');
    //ban details
   const [banDetails, setBanDetails] = useState(''); 
   //submitted  
   const [isSubmitted, setIsSubmitted] = useState(false);
  // Form data state
   const [formData, setFormData] = useState({
    reasonType: reasonType,
    reasonNote:''
   });
    // Handle form data validation errors
   const validateReasonForm = () => {
      let validationErrors = {};
      if (reasonType == 'SelectReason') { 
        //Select reason type
        validationErrors.reasonType = t('Select reason type');
      }
      if (!reasonNote.trim()) {
        // Reason note is required
        validationErrors.reasonNote = t('Reason note is required.');
      }
      // Update the state with the validation errors
      setErrors(validationErrors);
      // Form is valid if the errors object still has no properties
      return Object.keys(validationErrors).length === 0;
      };
      // Handle form submission
      const handleSubmit = (e) => {
        if(props.banStatus == 0){
          e.preventDefault();
          const isValid = validateReasonForm();
            if (isValid) {
            // Perform further processing or submit the form
            userBanCreate();    
            setIsSubmitted(true);
            setReasonType(reasonType);
            setReasonNote('');
          };
        }
        //Ban Delete
        if(props.banStatus == true){
        e.preventDefault();
        userBanDelete();    
        setIsSubmitted(true);
          setReasonType("SelectReason");
          setReasonNote('');
        }
      }
      // Handle dialog close
      const handleDialogClose = () => {
        props.setOpenDialog(false);
        clearErrorMessages();
        setLoading(true);
        setReasonType('SelectReason');
        setReasonNote('');    
      };
    // Clear error messages
      const clearErrorMessages = () => {
        setErrors({});
      };
     // Ban Create Object
      const banCreateObj = {
        "type": ""+props.selectType+"",
        "entityId": ""+props.selectedRowId+"",
        "reason": reasonNote,
        "userFriendlyBanReason": reasonType
      }
      // Ban Delete Object
      const banDeleteObj = {
        "type": ""+props.selectType+"",
        "entityId": ""+props.selectedRowId+""     
      }
      // Ban Create API Call 
      const userBanCreate = () => {
        setLoading(true);          
        usersService.userBanCreate(banCreateObj).then((response) => {  
            // Ban create status Update
            if(props.onUpdateStatus) {            
              props.onUpdateStatus(!props.banStatus);              
            }  
            // Ban create success message             
            showSnackbar(response.message.title, response.message.content, 'success');   
            props.setOpenDialog(false);   // Handle the response from the server                  
          })
          // Ban create error message
          .catch((error) => {
            console.log("Ban Error", error);
            showSnackbar(error.message.title, error.message.content, 'error');
          });
      };
      // Ban Delete API Call
      const userBanDelete = () => {
        setLoading(true);    
        usersService.userBanDelete(banDeleteObj).then((response) => {  
            //setBanDetails(response.result || {});   
            if(props.onUpdateStatus) {
              props.onUpdateStatus(!props.banStatus);          
            } 
            // Ban delete success message
            showSnackbar(response.message.title, response.message.content, 'success');   
            props.setOpenDialog(false);                  
          })
          // Ban delete error message
          .catch((error) => {
            console.log("Ban Error", error);
            showSnackbar(error.message.title, error.message.content, 'error');
          });
      };
 
  return (
    <>
       
      <Dialog open={props.openDialog} fullWidth={true} maxWidth={'sm'} PaperProps={{
          style: {maxHeight: '100%', borderRadius: '0px', height: 'auto', p: 0 }
        }} >
        <DialogTitle sx={{
          padding: '0px', borderBottom: 1, borderColor: 'divider'
        }}>
         
         <Grid item  sx={{padding: '10px 30px', lineHeight: '40px', backgroundColor: 'secondary3.main'}}>
         <Typography variant="heading1" color='secondary.main'>
          
         { props.banStatus == false && t('Ban')+' '+ props.selectType}
         { props.banStatus == true && t('Unban') +' '+ props.selectType}

            </Typography>
          <IconButton color="secondary4"
            aria-label="close"
            onClick={handleDialogClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8
            }}
            
          >
            <CloseRoundedIcon />
          </IconButton>
          </Grid>
        </DialogTitle>        
        
        <DialogContent dividers sx={{ p: 4, borderTop:'0px' }} >                
        <form onSubmit={handleSubmit}>
        <Box > 
              {/* Ban Create  */}
                { props.banStatus == false &&
                <>
               <Grid container spacing={3} >
                <Grid item md container direction="column" >
                     
                  <Typography variant="heading2" color={'secondary'} >{t('Ban Reason')}</Typography>
                  <FormControl>                    
                      <Select
                      name="reasonType"
                      id="subscription"
                      value={reasonType}
                      onChange={(e) => setReasonType(e.target.value)}
                      placeholder ="Select Reason"
                      sx={{ mb: 2 }}
                      size="small"                      
                      >
                      <MenuItem value="SelectReason" selected>{t('Select Reason')}</MenuItem>
                      <MenuItem value="ContactSupport">{t('Contact Support')}</MenuItem>
                      <MenuItem value="OpenInvoices">{t('Open Invoices')}</MenuItem> 
                      <MenuItem value="PaymentLimitReached ">{t('Payment Limit Reached')} </MenuItem>
                                                                
                      </Select>
                      {errors.reasonType && <FormHelperText error sx={{marginTop:'-10px'}}>{errors.reasonType}</FormHelperText>}  
                      <Typography variant="heading2" color={'secondary'} >{t('Reason Note')}</Typography>
                  <TextField type="text" size="small" placeholder="Reason Note" value={reasonNote} sx={{ mb: 2 }} onChange={(e) => setReasonNote(e.target.value)} error={Boolean(errors.reasonNote)} helperText={errors.reasonNote} />
                    </FormControl>
                    
                </Grid>
                </Grid>
                <DialogActions>
                  <Button type="submit" variant="contained" color="primary" sx={{}}>Submit </Button>
                </DialogActions>
                 
                 
                </>
              }
              {/* Ban CreateEND*/}

              {/* Ban Delete */}
              { props.banStatus == true &&
              <>
                   <Grid container spacing={3}>
                   <Grid sx={{
                    textAlign:'center', 
                    width:'100%'
                    }} item md container direction="column" >
                   <h4 >
                      {t('Are you sure! Do you want to Unban the '+props.selectType+'?')}</h4>
                   
                   </Grid>
                   </Grid>
                   <Grid container spacing={3} sx={{display:'flex', justifyContent:'center', marginTop:'10px'}}>                   
                   <Button type="submit" variant="contained" color="primary">Submit </Button>
                   </Grid>
                   </>
                }
                {/* Ban Delete END */}
           
          </Box>              
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
}