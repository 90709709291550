import { httpService } from "./common/http-service";
 

class UsersService {

    constructor() { }

  // Get all Users list from the API endpoint
  getAllUsers(queryParams) {    
    return httpService.get('/user/all', queryParams);
  }

  // Get User details By ID from the API endpoint
  getUserDetailsById(userId){  
    return httpService.get('/user?userId='+userId);
  }
 
  updateUserDetailsById(userId, formDataObj){
    return httpService.patch('/user?userId='+userId, formDataObj);
  }
  // Password Reset to User from the API endpoint 
  userPasswordResetLink(emailObj){  
    return httpService.post('/user/resettoken', emailObj);
  }

  // Creare User Ban from the API endpoint
  userBanCreate(banCreateObj){    
    return httpService.post('/ban', banCreateObj);
  }

  // Delete User Ban from the API endpoint
  userBanDelete(banDeleteObj){ 
    return httpService.delete('/ban', banDeleteObj);
  }

  // User Delete from the API endpoint
  userDelete(userId){  
    return httpService.delete('/user?userId='+userId);
  }
  
  // Update User Subscription from the API endpoint
  updateUserSubscription(userId, subscriptionData){    
    return httpService.patch('/user/subscription?userId='+userId, subscriptionData);
  }

    // Add User Vehicle from the API endpoint
  addUserVehicleById(userId, vehicleData){    
    return httpService.post('/vehicle?userId='+userId, vehicleData);
  }

  // Update User Vehicle from the API endpoint
  getUserVehicleById(vehicleId){
    //return httpService.post('https://staging.api.lekkerparkeren.nl/vehicle?userId='.userId);
  }

  // Delete User Vehicle from the API endpoint
  userVehicleDelete(licenseplate, userId){      
    return httpService.delete('/vehicle/'+licenseplate+'?userId='+userId);
  }
  
  // User Vehicle Ban from the API endpoint
  userVehicleBan(userId){  
    return httpService.post('/ban?Type=Vehicle&EntityId=21&Reason=User%20test&UserFriendlyBanReason=OpenInvoices');
  }

  // Get User Vehicles from the API endpoint
  getUserVehiclesById(userId){  
    return httpService.get('/vehicle/all?userId='+userId);
  }

  // Get User Parking History from the API endpoint
  getUserParkingHistory(userId, parkingSearchparams){  
    return httpService.get('/parkingsession/all?userId='+userId, parkingSearchparams);
  }
 
  // Get User Parking History Details from the API endpoint
   getUserParkingHistoryDetails(userId, parkingSessionId){  
    return httpService.get('/parkingsession/'+parkingSessionId+'?userId='+userId);
  }

  userParkingStop(ParkingSessionId, userId,  stopParkingObj){  
    return httpService.put('/parkingsession/'+ParkingSessionId+'?userId='+userId, stopParkingObj);
  }

  // Get User Logs from the API endpoint
  getUserInvoices(userId, invoiceParams) {     
    return httpService.get('/user/invoice/all?userId='+userId, invoiceParams);
  }

  // User Invoice Download from the API endpoint
downloadUserInvoices(userId, invId) {     
  return httpService.get('/user/invoice/'+invId+'/download?userId='+userId);
}

// User Invoice Email from the API endpoint
emailInvoices(userId, invId) {     
  return httpService.get('/user/invoice/'+invId+'/email?userId='+userId);
}
  // Get Vouchers from the API endpoint
  getVouchersHistory(userId){    
    return httpService.get('/user/promotion/all?userId='+userId);
  }
  // Get User Logs from the API endpoint
  getUserLogs(userId, logParams) {     
    return httpService.get('/user/log/all?userId='+userId, logParams);
  }
   // Get User Log details from the API endpoint
  getUserLogDetailsById(logId) { 
    return httpService.get('/user/log/'+logId);
  }
 
}

export const usersService = new UsersService();