// This is the create promotion component which is used to create a new promotion and edit the existing promotion details in the promotions page.
import React, { useContext, useEffect, useMemo, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import Typography from '@mui/material/Typography';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import Box from '@mui/material/Box';
import { Button, Card, CircularProgress, DialogActions, Fab, FormControl, Grid, MenuItem, Select, Stack, Switch, TextField, Tooltip } from '@mui/material';
import RemoveRoundedIcon from '@mui/icons-material/RemoveRounded';
import { useTranslation } from 'react-i18next';

import { promotionsService } from '../../services/promotions-service';
import { SnackbarContext } from '../../context/snackbar-context';
import { dateUtilitiesService } from '../../services/common/date-utilities-service';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/pro-regular-svg-icons';

export default function PromotionDetailsDialog(props) {

  // Admin details form
  const promotionsdetailsform = {
    id: 0,
    promotionName: '',
    status: 'Active',
    singleUse: true,
    ressetOccasion: '',
    amountofResets: '',
    onlyOnRegistration: true,
    startDate: null,
    endDate: null,
    code: '',
    totalAmountOfTimesUsed: 0,
  }
  // Reset Occasion List 
  const resetOccasionList = [
    { id: 'NoReset', name: 'No Reset' },
    { id: 'ResetAfterCompletion', name: 'Reset After Completion' },
    { id: 'ResetNextMonth', name: 'Reset Next Month' }
  ]
  // Reward Type List
  const rewardTypeList = [
    { id: 1, value: 'NoSubscriptionOrTransactionCost', name: 'No Subscription or Transaction Cost' }
  ]
  // Criteria Type List
  const criteriaTypeList = [
    { id: 1, value: 'MinimalParkingCostsInTimeframe', name: 'Minimal Parking Costs In Timeframe', }
  ]
  // Interval Type List for the criteria type
  const intervalTypeList = ['Day', 'Month']

  // use translation hook
  const { t } = useTranslation("General");
  // Snackbar - success and error messages
  const { showSnackbar } = useContext(SnackbarContext);
  // State for loading
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingDetails, setIsLoadingDetails] = useState(false);
  // State to store the status of form submission 
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  // State to store promotion form is in edit mode or not
  const [isEditPromotion, setIsEditPromotion] = useState(true);
  // state to store all the promotion details
  const [promotionFormData, setPromotionFormData] = useState({ ...promotionsdetailsform });
  // state to store all the criteria list
  const [criterias, setCriterias] = useState([]);
  // State to open and close the criteria type dialog to add new criteria type
  const [openCriteriaTypeDialog, setOpenCriteriaTypeDialog] = useState(false);
  // State to store the selected criteria type from the criteria type dialog
  const [selectedCriteriaType, setSelectedCriteriaType] = useState(' ');
  // state to store all the rewards list 
  const [rewards, setRewards] = useState([]);
  // State to open and close the reward type dialog to add new reward type
  const [openRewardTypeDialog, setOpenRewardTypeDialog] = useState(false);
  // State to store the selected reward type from the reward type dialog 
  const [selectedRewardType, setSelectedRewardType] = useState(' ');

  // Promotion Details Block 
  // To handle the reset occasion dropdown change in the promotion details form and to set the amount of resets value to 0 if the dropdown value is NoReset
  const onChangeResetOccation = (e) => {
    setPromotionFormData((prevData) => ({
      ...prevData,
      ressetOccasion: e.target.value,
      amountofResets: e.target.value === 'NoReset' ? 0 : promotionFormData.amountofResets || ''
    }));
  };
  // To handle the input fields like promotion name, code, amount of resets change in the promotion details form
  const onChangeTextField = (e) => {
    const { name, value } = e.target;
    setPromotionFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  // To handle the start date change in the promotion details form
  const onSelectStartDate = (date) => {
    setPromotionFormData((prevData) => ({
      ...prevData,
      startDate: date || null
    }));
  };
  // To handle the end date change in the promotion details form
  const onSelectEndDate = (date) => {
    setPromotionFormData((prevData) => ({
      ...prevData,
      endDate: date || null
    }));
  };
  // To handle the singleUse input switch toggle and to set the code value to 1 if the switch is off
  const onChangeSingleUse = (e) => {
    setPromotionFormData((prevData) => ({
      ...prevData,
      singleUse: e.target.checked,
      code: e.target.checked === false ? 1 : ''
      // code: e.target.checked === false ? 1 : promotionFormData.code || ''
    }));
  };
  // To handle the onlyOnRegistration input field switch toggle change
  const onChangeOnlyOnRegistration = (e) => {
    setPromotionFormData((prevData) => ({
      ...prevData,
      onlyOnRegistration: e.target.checked
    }));
  };

  // Promotion Criteria Block
  // onChange handler for the criteria type dropdown from the criteria type dialog
  const handleCriteriaTypeChange = (event) => {
    setSelectedCriteriaType(event.target.value);
    addCriteria(event.target.value);
  };
  // To handle the input fields like timeframe, interval length and interval type change in the criteria list
  const handleInputChange = (e, recordIndex) => {
    const { name, value } = e.target;
    setCriterias((prevCriteria) => {
      const updatedCriteria = [...prevCriteria];
      updatedCriteria[recordIndex][name] = value;
      return updatedCriteria;
    });
  };
  // To add the criteria to the list and open the criteria type dialog if the criteria is not already added to the list 
  const addCriteria = (criteriaType) => {
    if (criterias.length && criterias.some((obj) => obj.criteriaType === criteriaType.value)) {
      setOpenCriteriaTypeDialog(false);
    } else {
      setCriterias((prevCriteria) => [
        ...prevCriteria,
        { criteriaName: criteriaType.name, criteriaType: criteriaType.value, timeFrame: '', intervalLength: '', intervalType: ' ' }, //, editable: true
      ]);
      setOpenCriteriaTypeDialog(false);
    }
    setSelectedCriteriaType(' ');
  };
  // To remove the criteria from the list and reset the reward list if all the criteria are removed
  const removeCriteria = (criteria) => {
    setCriterias((prevData) => prevData.filter((obj) => obj.criteriaType !== criteria.criteriaType));
    if (criterias.length === 1) {
      // Reset the Reward list if all the criteria are removed
      setRewards([]);
    }
  };

  // Promotion Rewards Block
  // To open the reward type dialog to add new reward type if the reward type is not already added to the list
  const addReward = (rewardType) => {
    if (rewards.length && rewards.some((obj) => obj.rewardType === rewardType.value)) {
      setOpenRewardTypeDialog(false);
    } else {
      setRewards((prevCriteria) => [
        ...prevCriteria,
        { rewardType: rewardType.value, months: '', rewardName: rewardType.name }, //, editable: true
      ]);
      setOpenRewardTypeDialog(false);
    }
    setSelectedRewardType(' ');
  };
  // To handle the input change in the reward list 
  const handleRewardsInputChange = (e, recordIndex) => {
    const { name, value } = e.target;
    setRewards((prevRewards) => {
      const updatedRewards = [...prevRewards];
      updatedRewards[recordIndex][name] = value;
      return updatedRewards;
    });
  };
  // remove selected reward from the list when the remove button is clicked 
  const removeReward = (reward) => {
    setRewards((prevData) => prevData.filter((obj) => obj.rewardType !== reward.rewardType));
  };

  // Dialog close handler to reset the form, close the dialog box and set the state to initial state of the form
  const handlePromotionDialogClose = () => {
    if (props.onClosePromotionDialog) props.onClosePromotionDialog();
    resetForm();
    setPromotionFormData({ ...promotionsdetailsform });
  };
  // Reset Form and set the state to initial state
  const resetForm = () => {
    setPromotionFormData({ ...promotionsdetailsform });
    setCriterias([]);
    setRewards([]);
    setIsEditPromotion(true)
    setIsFormSubmitted(false);
    setIsLoading(false);
  }
  // Api call to get the promotion details by ID this will be called only when the edit button is clicked and form is opened in edit mode with the details 
  useEffect(() => {
    setPromotionFormData({ ...promotionsdetailsform });
    const getPromotionDetailsbyID = () => {
      setIsLoadingDetails(true)
      promotionsService.getPromotionbyID(props.data).then((response) => {
        let promotionsdetailsform = {
          id: response['result'].id || 0,
          promotionName: response['result'].name || '',
          status: response['result'].status || '',
          ressetOccasion: response['result'].resetOccasion || '',
          amountofResets: response['result'].amountOfResets || 0,
          startDate: dayjs(response['result'].startDate.date),
          endDate: dayjs(response['result'].endDate.date),
          onlyOnRegistration: response['result'].onlyOnRegistration,
          totalAmountOfTimesUsed: response['result'].totalAmountOfTimesUsed || 0,
        }
        setPromotionFormData({ ...promotionsdetailsform });

        let criterias = [];
        response['result'].criteria.forEach((criteria) => {
          let parsedObject = JSON.parse(criteria.criteriaDataJson);
          let criteriaObj = {
            criteriaName: getCriteriaName(criteria.criteriaType),
            criteriaType: criteria.criteriaType,
            timeFrame: parsedObject.MinParkingCostInTimeframe,
            intervalLength: parsedObject.IntervalLength,
            intervalType: parsedObject.IntervalType || ''
          }
          criterias.push(criteriaObj);
        });
        setCriterias(criterias);

        let rewards = [];
        response['result'].rewards.forEach((reward) => {
          let parsedObject = JSON.parse(reward.rewardDataJson);
          let rewardObj = {
            rewardName: getRewardName(reward.rewardType),
            rewardType: reward.rewardType,
            months: parsedObject.MonthsOfNoSubscriptionOrTransactionCosts
          }
          rewards.push(rewardObj);
        }
        );
        setRewards(rewards);

        setIsLoadingDetails(false);
      }, (error) => {
        setIsLoadingDetails(false);
        console.log("Promotion Details Error", error);
        showSnackbar(error.message.title, error.message.content, 'error');
      });
    };
    if (props.data) {
      setIsEditPromotion(false);
      getPromotionDetailsbyID();
    }
    // return () => {};
  }, [props.data, props.actionType]);
  // Call back function to get the criteria name based on the criteria type to bind the form in edit mode
  const getCriteriaName = (criteriaType) => {
    const criteria = criteriaTypeList.find((obj) => obj.value === criteriaType);
    return criteria ? criteria.name : '';
  };
  // Call back function to get the reward name based on the reward type to bind the form in edit mode
  const getRewardName = (rewardType) => {
    const reward = rewardTypeList.find((obj) => obj.value === rewardType);
    return reward ? reward.name : '';
  };
  // **********************************************Promotion Rewards Block
  // Api call to save the promotion details while creating and updating the promotion
  const savePromotionDetails = () => {
    setIsFormSubmitted(true);
    if (isLoading) {
      return true;
    }
    let criteriaList = [];
    criterias.forEach((criteria) => {
      let criteriaObj = {
        "criteriaType": criteria.criteriaType,
        "criteriaDataJson": JSON.stringify({
          "MinParkingCostInTimeframe": +criteria.timeFrame,
          "IntervalType": criteria.intervalType || '',
          "IntervalLength": +criteria.intervalLength
        })
      }
      criteriaList.push(criteriaObj);
    });

    let rewardList = [];
    rewards.forEach((reward) => {
      let rewardObj = {
        "rewardType": reward.rewardType,
        "rewardDataJson": JSON.stringify({ "MonthsOfNoSubscriptionOrTransactionCosts": +reward.months })
      }
      rewardList.push(rewardObj);
    });

    setIsLoading(true);
    if (props.data) {
      let promotionObject = {
        "name": promotionFormData.promotionName || '',
        "onlyOnRegistration": promotionFormData.onlyOnRegistration,
        "startDate": dateUtilitiesService.getUnixTimestamp(promotionFormData.startDate) || null,
        "endDate": dateUtilitiesService.getUnixTimestamp(promotionFormData.endDate) || null,
        "resetOccasion": promotionFormData.ressetOccasion || null,
        "amountOfResets": +promotionFormData.amountofResets || 0,
        "criteria": criteriaList || [],
        "rewards": rewardList || []
      }
      promotionsService.updatePromotion(props.data, promotionObject).then((response) => {
        setIsLoading(false);
        handlePromotionDialogClose();
        if (props.onUpdatePromotion) {
          props.onUpdatePromotion(response.result)
        }
        showSnackbar(response.message.title, response.message.content, 'success');
      }, (error) => {
        setIsLoading(false);
        console.log("Update Promotion Error", error);
        showSnackbar(error.message.title, error.message.content, 'error');
      });
    } else {
      let promotionObject = {
        "name": promotionFormData.promotionName || '',
        "singleUse": promotionFormData.singleUse || false,
        "onlyOnRegistration": promotionFormData.onlyOnRegistration,
        "amountOfVoucherCodes": +promotionFormData.code || null,
        "startDate": dateUtilitiesService.getUnixTimestamp(promotionFormData.startDate) || null,
        "endDate": dateUtilitiesService.getUnixTimestamp(promotionFormData.endDate) || null,
        "resetOccasion": promotionFormData.ressetOccasion || null,
        "amountOfResets": +promotionFormData.amountofResets || 0,
        "criteria": criteriaList || [],
        "rewards": rewardList || []
      }
      promotionsService.createPromotion(promotionObject).then((response) => {
        setIsLoading(false);
        showSnackbar(response.message.title, response.message.content, 'success');
        if (props.onCreatePromotion) {
          props.onCreatePromotion(response.result)
        }
        handlePromotionDialogClose();
      }, (error) => {
        setIsLoading(false);
        console.log("Create Promotion Error", error);
        showSnackbar(error.message.title, error.message.content, 'error');
      });
    }
  };
  // validate the promotion name while creating and updating the promotion name
  const validatePromotionName = (event) => {
    if (event.target.value.trim()) {
      setIsEditPromotion(false);
    } else {
      setIsEditPromotion(true);
    }
  }

  return (
    <>
      <Dialog
        open={props.openDialog}
        fullWidth={true}
        maxWidth={'lg'}
        PaperProps={{
          style: {
            maxHeight: '100%',
            height: '100%',
            borderRadius: '0px',
            p: 0
          }
        }}
      >
        <DialogTitle sx={{
          px: 0, py: 0, borderBottom: 1, borderColor: 'divider', backgroundColor: 'secondary3.main'
        }} >
          {!isLoadingDetails && <Grid
            border={0}
            sx={{ py: 1 }}
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center" columns={12}>
            <Grid sx={{ mx: 3 }} item md container border={0}>
              <Grid item>
                {isEditPromotion &&
                  <TextField placeholder={t('Promotion Name')} hiddenLabel size="small" variant="standard" color="secondary"
                    name="promotionName"
                    value={promotionFormData.promotionName}
                    onChange={onChangeTextField} onBlur={(e) => validatePromotionName(e)}
                    error={isFormSubmitted && !promotionFormData.promotionName}
                    inputProps={{
                      maxLength: 50,
                    }}
                  />
                }
              </Grid>
              <Grid item container alignItems={'center'}>
                <Grid border={0} item zeroMinWidth sx={{ maxWidth: { xs: '50%', sm: '80%', md: '50%' } }} >
                  {!isEditPromotion && <Typography component={'div'} noWrap variant="heading3">{promotionFormData.promotionName}</Typography>}
                </Grid>

                <Grid item>
                  {(!isEditPromotion && promotionFormData.totalAmountOfTimesUsed === 0) && <Typography variant="text3"
                    color="text.secondary" onClick={() => { setIsEditPromotion(true) }}
                    sx={{ cursor: 'pointer', mx: 2 }}>{t('Edit')}</Typography>}
                </Grid>
              </Grid>

            </Grid>
            <Grid sx={{ mx: 3 }} item md container border={0}>
              <Typography variant="heading3">{t('Status')}</Typography>
              <Typography sx={{ mx: 3 }} variant="text3" color="text.secondary">{promotionFormData.status}</Typography>
            </Grid>
            <Grid sx={{ mx: 1 }} item md container border={0} alignItems={'center'} justifyContent={'flex-end'}>
              <Grid item sx={{ mr: 3 }}>
                {promotionFormData.totalAmountOfTimesUsed === 0 &&
                  <Button variant="contained" color="primary" onClick={savePromotionDetails}>
                    {!isLoading && t('Save')}
                    {isLoading && <CircularProgress size={25} thickness={3} color='secondary' variant="indeterminate" />
                    }
                  </Button>}
              </Grid>
              <Grid item >
                <IconButton color="secondary4" onClick={handlePromotionDialogClose} >
                  <CloseRoundedIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
          }
          {isLoadingDetails && <Grid
            border={0}
            sx={{ py: 1 }}
            container
            direction="row"
            justifyContent="center"
            alignItems="center" columns={12}>
            <Grid sx={{ m: 0.35 }} > <CircularProgress size={25} color={'secondary'} /></Grid>
          </Grid>}
        </DialogTitle>
        <DialogContent dividers sx={{ p: 0, background: '#FBFAFC' }} >
          {/* Promotion Details Block*/}
          <Box m={3} >
            <Card sx={{ p: 2 }} >
              <Grid container >
                <Grid spacing={3}
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="flex-start"
                >
                  <Grid item md container direction="column" spacing={2}>
                    {/* Single Use */}
                    {!props.data && <Grid item md container direction="column" >
                      <FormControl fullWidth variant="standard">
                        <Stack direction="row" spacing={1} alignItems="center">
                          <Typography variant="heading2" color={'secondary'} >{t('Single Use')}
                          </Typography>
                          <Tooltip arrow title={t('SingleUseInfo')}>
                            <FontAwesomeIcon icon={faCircleInfo} size="sm" style={{ color: '#31194D' }} />
                          </Tooltip>
                        </Stack>
                        <Stack direction="row" spacing={1} alignItems="center">
                          <Typography>{t('Disabled')}</Typography>
                          <Switch disabled={promotionFormData.totalAmountOfTimesUsed > 0} color="secondary" checked={promotionFormData.singleUse} onChange={onChangeSingleUse} />
                          <Typography>{t('Enabled')}</Typography>
                        </Stack>
                      </FormControl>
                    </Grid>}
                    {props.data && <Grid item md container direction="column" sx={{ minHeight: '86px', display: { xs: 'none', sm: 'none', md: 'block' } }} >
                    </Grid>}

                    {/* Reset Occasion */}
                    <Grid item md container direction="column" >
                      <FormControl fullWidth >
                        <Stack direction="row" spacing={1} alignItems="center">
                          <Typography variant="heading2" color={'secondary'} >{t('Reset Promotion')} </Typography>
                          </Stack>
                        <Select size="small"
                          name='ressetOccasion'
                          value={promotionFormData.ressetOccasion}
                          onChange={onChangeResetOccation}
                          displayEmpty
                          inputProps={{ 'aria-label': 'Without label' }}
                          error={!promotionFormData.ressetOccasion && isFormSubmitted}
                          disabled={promotionFormData.totalAmountOfTimesUsed > 0}
                        >
                          <MenuItem value="">
                            {t('Select')}
                          </MenuItem>
                          {resetOccasionList.map((obj, index) =>
                            <MenuItem value={obj.id} key={index}>{obj.name}</MenuItem>
                          )};
                        </Select>
                      </FormControl>
                    </Grid>
                    {/* Amount of Resets */}
                    <Grid item md container direction="column" >
                      <FormControl fullWidth variant="standard">
                        {/* <Typography variant="heading2" color={'secondary'} >{t('Amount of Resets')}</Typography> */}
                        <Stack direction="row" spacing={1} alignItems="center">
                          <Typography variant="heading2" color={'secondary'} >{t('Amount of Resets')}</Typography>
                        </Stack>
                        <TextField
                          type="number"
                          name="amountofResets"
                          value={promotionFormData.amountofResets}
                          onChange={onChangeTextField}
                          size="small"
                          error={!promotionFormData.amountofResets && isFormSubmitted}
                          disabled={promotionFormData.ressetOccasion === 'NoReset' || promotionFormData.totalAmountOfTimesUsed > 0}
                        >
                        </TextField>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid item md container direction="column" spacing={2}>
                    {/* Only on Registration */}
                    <Grid item md container direction="column" >
                      <FormControl fullWidth variant="standard">
                        {/* <Typography variant="heading2" color={'secondary'} >{t('Only On Registration')}</Typography> */}
                        <Stack direction="row" spacing={1} alignItems="center">
                          <Typography variant="heading2" color={'secondary'} >{t('Only On Registration')}</Typography>
                          <Tooltip arrow title={t('OnlyOnRegistrationInfo')}>
                            <FontAwesomeIcon icon={faCircleInfo} size="sm" style={{ color: '#31194D' }} />
                          </Tooltip>
                        </Stack>
                        <Stack direction="row" spacing={1} alignItems="center">
                          <Typography>{t('Disabled')}</Typography>
                          <Switch disabled={promotionFormData.totalAmountOfTimesUsed > 0} checked={promotionFormData.onlyOnRegistration} onChange={onChangeOnlyOnRegistration} color="secondary" />
                          <Typography>{t('Enabled')}</Typography>
                        </Stack>
                      </FormControl>
                    </Grid>
                    {/* Start Date*/}
                    <Grid item md container direction="column" >
                      <FormControl fullWidth variant="standard">
                        {/* <Typography variant="heading2" color={'secondary'} >{t('Start Date')}</Typography> */}
                        <Stack direction="row" spacing={1} alignItems="center">
                          <Typography variant="heading2" color={'secondary'} >{t('Start Date')}</Typography>
                         </Stack>
                        <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>

                          <DesktopDatePicker
                            value={promotionFormData.startDate}
                            name='startDate'
                            onChange={onSelectStartDate}
                            format="DD-MM-YYYY"
                            slotProps={{
                              textField: {
                                helperText: '',
                                size: 'small',
                                error: promotionFormData.startDate === null && isFormSubmitted === true
                              }
                            }}
                            disabled={promotionFormData.totalAmountOfTimesUsed > 0}

                          />
                        </LocalizationProvider>
                      </FormControl>
                    </Grid>
                    {/* End Date */}
                    <Grid item md container direction="column" >
                      <FormControl fullWidth variant="standard">
                        {/* <Typography variant="heading2" color={'secondary'} >{t('End Date')}</Typography> */}
                        <Stack direction="row" spacing={1} alignItems="center">
                          <Typography variant="heading2" color={'secondary'} >{t('End Date')}</Typography>
                         </Stack>
                        <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
                          {/* <DemoContainer components={['DatePicker']}> */}
                          <DesktopDatePicker
                            format="DD-MM-YYYY"
                            slotProps={{ textField: { helperText: '', size: 'small', error: promotionFormData.endDate === null && isFormSubmitted === true } }}
                            minDate={promotionFormData.startDate}
                            name='endDate'
                            value={promotionFormData.endDate}
                            onChange={onSelectEndDate}
                            disabled={promotionFormData.totalAmountOfTimesUsed > 0}
                          />
                          {/* </DemoContainer> */}
                        </LocalizationProvider>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid item md container direction="column" spacing={2}>
                    {/* Code */}
                    <Grid item md container direction="column" sx={{ minHeight: '86px', display: { xs: 'none', sm: 'none', md: 'block' } }} >
                    </Grid>
                    {!props.data && <Grid item md container direction="column" >
                      <FormControl fullWidth variant="standard">
                        {/* <Typography variant="heading2" color={'secondary'} >{t('Code')}</Typography> */}
                        <Stack direction="row" spacing={1} alignItems="center">
                          <Typography variant="heading2" color={'secondary'} >{t('Code')}</Typography>
                         </Stack>
                        <TextField
                          type="number"
                          name="code"
                          value={promotionFormData.code}
                          onChange={onChangeTextField}
                          inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                          size="small"
                          error={!promotionFormData.code && isFormSubmitted}
                          disabled={!promotionFormData.singleUse || promotionFormData.totalAmountOfTimesUsed > 0}
                        >
                        </TextField>
                      </FormControl>
                    </Grid>
                    }
                  </Grid>
                </Grid>
              </Grid>
            </Card>
          </Box>

          {/* Criteria Block*/}
          <Box m={3} >
            <Card sx={{ p: 2 }} >
              {criterias.length === 0 && <Grid container sx={{ mb: 2 }}>
                {/* <Typography variant="heading1" color={'secondary'} >Criteria </Typography> */}
                <Stack direction="row" spacing={1} alignItems="center">
                  <Typography variant="heading1" color={'secondary'} >Criteria </Typography>
                  </Stack>
              </Grid>}
              {criterias.map((criteria, index) =>
                <Grid container key={index} sx={{ mb: 3 }} justifyContent="space-between"
                  alignItems="flex-end" >
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center" >

                    <Grid item xs={10} sm={6}>
                      {/* <Typography variant="heading1" color={'secondary'} >Criteria: {criteria.criteriaName} </Typography> */}
                      <Stack direction="row" spacing={1} alignItems="center">
                        <Typography variant="heading1" color={'secondary'} >Criteria: {criteria.criteriaName} </Typography>
                      </Stack>
                    </Grid>
                    <Grid item >
                      {promotionFormData.totalAmountOfTimesUsed === 0 &&
                        <Tooltip arrow title={t('Remove')}>
                          <Fab color='secondary3' size="xsmall" onClick={() => removeCriteria(criteria)}>
                            <RemoveRoundedIcon />
                          </Fab>
                        </Tooltip>
                      }
                    </Grid>
                  </Grid>
                  <Grid spacing={3}
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="flex-start"
                    key={index}
                  >
                    {/* TimeFrame */}
                    <Grid item md container direction="column">
                      <Typography variant="heading2" color={'secondary'} >{criteria.criteriaName}</Typography>
                      <TextField
                        type="number"
                        name="timeFrame"
                        size="small"
                        value={criteria.timeFrame}
                        onChange={(e) => handleInputChange(e, index)}
                        error={!criteria.timeFrame && isFormSubmitted}
                        disabled={promotionFormData.totalAmountOfTimesUsed > 0}
                      />
                      {/* disabled={!criteria.editable} */}
                    </Grid>
                    {/* Interval Type */}
                    <Grid item md container direction="column" >
                      <Typography variant="heading2" color={'secondary'}>{t('Interval Type')}</Typography>
                      <Select
                        name="intervalType"
                        size="small"
                        value={criteria.intervalType}
                        onChange={(e) => handleInputChange(e, index)}
                        error={!criteria.intervalType.trim() && isFormSubmitted}
                        disabled={promotionFormData.totalAmountOfTimesUsed > 0}
                      >
                        <MenuItem value=" ">
                          {t('Select')}
                        </MenuItem>
                        {intervalTypeList.map((obj, index) =>
                          <MenuItem key={index} value={obj}>{obj}</MenuItem>
                        )};
                      </Select>
                    </Grid>
                    {/* Interval length */}
                    <Grid item md container direction="column" >
                      <Typography variant="heading2" color={'secondary'} >{t('Interval Length')}</Typography>
                      <TextField
                        name="intervalLength"
                        size="small"
                        type="number"
                        value={criteria.intervalLength}
                        onChange={(e) => handleInputChange(e, index)}
                        error={!criteria.intervalLength && isFormSubmitted}
                        disabled={promotionFormData.totalAmountOfTimesUsed > 0}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              )
              }
              {promotionFormData.totalAmountOfTimesUsed === 0 && <Grid container >
                <Button variant="contained" color="primary" onClick={() => setOpenCriteriaTypeDialog(true)} >{t('Add Criteria')} </Button>
              </Grid>
              }
            </Card>
          </Box>

          {/* Reward Block*/}
          <Box m={3}>
            <Card sx={{ p: 2 }}>
              {rewards.length === 0 && <Grid sx={{ mb: 1 }} >
                {/* <Typography variant="heading1" color={'secondary'} >{t('Reward')}</Typography> */}
                <Stack direction="row" spacing={1} alignItems="center">
                  <Typography variant="heading1" color={'secondary'} >{t('Reward')}</Typography>
                 </Stack>
              </Grid>}
              {rewards.map((reward, index) =>
                <Grid container key={index}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center">
                    <Grid item xs={10} sm={6}>
                      {/* <Typography variant="heading1" color={'secondary'} >{t('Reward')}</Typography> */}
                      <Stack direction="row" spacing={1} alignItems="center">
                        <Typography variant="heading1" color={'secondary'} >{t('Reward')}</Typography>
                      </Stack>
                    </Grid>
                    <Grid item >
                      {promotionFormData.totalAmountOfTimesUsed === 0 &&
                        <Tooltip arrow title={t('Remove')}>
                          <Fab color='secondary3' size="xsmall" onClick={() => removeReward(reward)}>
                            <RemoveRoundedIcon />
                          </Fab>
                        </Tooltip>
                      }
                    </Grid>
                  </Grid>
                  <Grid sx={{ mb: 2 }} spacing={3}
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="flex-start"
                    key={index}
                  >
                    {/* Interval length */}
                    <Grid item md container direction="column" >
                      <Typography variant="heading2" color={'secondary'} >{reward.rewardName}</Typography>
                      <TextField
                        name="months"
                        size="small"
                        type="number"
                        value={reward.months}
                        onChange={(e) => handleRewardsInputChange(e, index)}
                        error={!reward.months && isFormSubmitted}
                        disabled={promotionFormData.totalAmountOfTimesUsed > 0}
                      />
                    </Grid>
                    <Grid item md container direction="column"></Grid>
                    <Grid item md container direction="column"></Grid>
                  </Grid>
                </Grid>
              )}
              {promotionFormData.totalAmountOfTimesUsed === 0 && <Grid container sx={{ mt: 1 }}>
                <Button disabled={criterias.length === 0} variant="contained" color="primary" onClick={() => setOpenRewardTypeDialog(true)}>{t('Add')} {t('Reward')}</Button>
              </Grid>}
            </Card>
          </Box>
        </DialogContent>

      </Dialog>
      {/* Criteria Type Dialog */}
      <Dialog fullWidth={true} maxWidth={'xs'} open={openCriteriaTypeDialog} >
        <DialogTitle>{t('Select criteria type')}</DialogTitle>
        <DialogContent>
          <Select fullWidth={true}
            value={selectedCriteriaType}
            onChange={handleCriteriaTypeChange}
          >
            <MenuItem key='0' disabled value=" ">
              {t('Select criteria type')}
            </MenuItem>
            {criteriaTypeList.map((criteriaType, index) =>
              <MenuItem key={index} value={criteriaType}>{criteriaType.name}</MenuItem>
            )}
          </Select>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenCriteriaTypeDialog(false)}>{t('Close')}</Button>
        </DialogActions>
      </Dialog>
      {/* Rewards Type Dialog */}
      <Dialog fullWidth={true} maxWidth={'xs'} open={openRewardTypeDialog} >
        <DialogTitle>{t('Select reward type')}</DialogTitle>
        <DialogContent>
          <Select fullWidth={true}
            value={selectedRewardType}
            onChange={(event) => { addReward(event.target.value) }}
          >
            <MenuItem key='0' value=' '>{t('Select reward type')}</MenuItem>
            {rewardTypeList.map((rewardType, index) =>
              <MenuItem key={index} value={rewardType}>{rewardType.name}</MenuItem>
            )}
          </Select>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenRewardTypeDialog(false)}>{t('Close')}</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}