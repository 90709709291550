import { Box, Button, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { usersService } from "../../services/users-service";
import { SnackbarContext } from "../../context/snackbar-context";
import LPCustomTable from "../../ui-components/lp-custom-table/lp-custom-table";
import UserLogDetails from "./user-log-details";
import { t } from "i18next";


export default function UserVouchers(props){    
  // Snackbar - success and error messages
  const { showSnackbar } = useContext(SnackbarContext);
  // state variable to store user id 
  const[userId, setUserID] = React.useState(props.userId);
  // state variable to store open and close dialog
  const [openDialog, setOpenDialog] = React.useState(false);
  // state variable to store users Vouchers list
  const [userVouchersList, setUserVouchersList] = useState([]);
  // state variable to store pagination page number
  const [page, setPage] = useState(1);
  // state variable to store search param
  const [searchParam, setSearchParam] = useState(null);
  // state variable to store pagination amount per page
  const [paginationAmountPerPage, setPaginationAmountPerPage] = useState(50);
  // state variable to store and validate more records availbel for next scroll to call endpoint
  const [hasMore, setHasMore] = useState(true);
  // loading state
  const [loading, setLoading] = useState(false);
  // columns to show in table
  const [columns, setColumns] = useState([
    { id: 'promotionName', label: 'Promotion', minWidth: 200, align:'left', width:350 },
    { id: 'assignedDate', label: 'Assigned Date', minWidth: 150, align:'left', width:200 },
    { id: 'codeUsed', label: 'Code Used', minWidth: 150 },
    ])

  /***
   * Get all Vouchers list from API and set to state variable 
   * Vouchers will be called on page load    *  
   * */

  
  useEffect(() => {
    let userSearchparams = {
      PaginationPage: page,
      PaginationAmountPerPage: paginationAmountPerPage,
      SearchValue: searchParam || null,
      SearchField: 'SearchIndex'
    }    
    const getUserVouchersHistory = () => {
      setLoading(true);
      usersService.getVouchersHistory(props.userId).then((response) => {        
        const newData = response.result;
        if (newData.length > 0) {
          newData.forEach((vouchersHistory) => {            
            /*userLog.deviceId = userLog.os+ ', '+ userLog.osVersion+', '+userLog.appVersion; */
            vouchersHistory.assignedDate = vouchersHistory.activationDate['formattedDate'];
            //vouchersHistory.endDate = vouchersHistory.endDate['formattedDate'];            
          });
          //set Voucher list data
          if (searchParam) {
            setUserVouchersList(newData);
          } else {
            setUserVouchersList((prevData) => [...prevData, ...newData]);
          }  
          
            setHasMore(false);
                
        } else {
          //set HasMore to false when no more records available
          if (searchParam) {
            setUserVouchersList(newData);
          } else {
            setUserVouchersList((prevData) => [...prevData, ...newData]);
          }
          setHasMore(false);
        }
        //set Loading to false
        setLoading(false);        
      }, (error) => {
        //set Loading to false
        setLoading(false);
        //show error message        
        showSnackbar(error.message.title, error.message.content, 'error');
      });
    };
    if(props.userId){
      getUserVouchersHistory(); 
    }
    // return () => {};
  }, [page]);

  // update page number on scroll
  const updatePage = () => {
    setPage((prevPage) => prevPage + 1);
  }

  return (
    <>
      <Box >
        <Box mb={2}>
          <Typography variant="heading1" color="secondary"> {t('Voucher History')} </Typography>
        </Box>
      </Box>
      <LPCustomTable columns={columns} data={userVouchersList} updatePage={updatePage} hasMore={hasMore} loading={loading} />
     </>
  );
}


