import React, { useContext, useEffect, useState } from "react";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Grid, Tab, Tabs, TextField,  FormControl, InputLabel, MenuItem, Select, Button, FormHelperText, DialogActions  } from '@mui/material';
import { usersService } from '../../services/users-service';
import { SnackbarContext } from "../../context/snackbar-context";
import { useTranslation } from "react-i18next";


export default function UserSubscription(props) {
   // to get the translation texts   
  const { t } = useTranslation("General"); 
  // State for error messages  
  const [errors, setErrors] = useState({});
  // State for loading User ID
  const [userId, setUserId] = useState(props.selectedUser);
   // Snackbar - success and error messages
   const { showSnackbar } = useContext(SnackbarContext);
   // State for loading dialog
   const [loading, setLoading] = useState(false);  
   // State for loading subscription details
   const [subscriptionDetails, setSubscriptionDetails] = useState('');
   // State for loading submit
   const [isSubmitted, setIsSubmitted] = useState(false);
   // State for loading form data
   const [formData, setFormData] = useState({
    subscription: '',
   });
   // State for loading initial value
   const initialValue = props.subscriptionVal; 
   useEffect(() => {    
    setFormData({ ...formData, subscription: props.subscriptionVal });
    
  }, [props.subscriptionVal]);
  // Handle Subscription change
   const handleSubscriptionChange = (e) => {     
     const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
   };
   // Validate Subscription
   const validateSubscriptionForm = () => {     
      return formData.subscription !== 'Select Subscription';
    };
    // Handle submit
   const handleSubmit = (e) => {    
     e.preventDefault();
     const isValid = validateSubscriptionForm();
     if (isValid) {      
     updateUserSubscription();     
     setIsSubmitted(true);     
   };
   }
   // Handle close dialog
  const handleDialogClose = () => {    
    props.setOpenDialog(false);    
    setLoading(true);
     resetSubscriptionValue();
    //setUserLogDetails({});
  };
// Reset Subscription Value
  const resetSubscriptionValue = () => {    
    formData.subscription = initialValue;
  };
// Update User Subscription
  const updateUserSubscription = () => {
    let subscriptionData = {
      "changeSubscriptionForActiveMonth": true,
    "subscriptionType": formData.subscription    
       
    }  
    // User Subscription API call
    usersService.updateUserSubscription(props.selectedUser, subscriptionData)
    .then((response) => { 
      setSubscriptionDetails(response.result || {}); 
      showSnackbar(response.message.title, response.message.content, 'success'); 
      props.onSubscriptionUpdate(formData.subscription, props.selectedUser);  
      props.setOpenDialog(false);      
    })
     .catch((error) => {
      console.log("Subscription change Error", error);
      showSnackbar(error.message.title, error.message.content, 'error');
      
    });
  };

  
  return (
    <>
       
      <Dialog
        open={props.openDialog}
        fullWidth={true}
        maxWidth={'sm'}
        PaperProps={{
          style: {
            maxHeight: '100%',
            borderRadius: '0px',
            height: 'auto',
            p: 0
          }
        }}
      >
        <DialogTitle sx={{
          padding: '0px', borderBottom: 1, borderColor: 'divider'
        }}>
         
         <Grid item  sx={{padding: '10px 30px', lineHeight: '40px', backgroundColor: 'secondary3.main'}}>
         <Typography variant="heading1" color='secondary.main'>{t('Change Subscription')}
            </Typography>
          <IconButton color="secondary4"
            aria-label="close"
            onClick={handleDialogClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8
            }}
            
          >
            <CloseRoundedIcon />
          </IconButton>
          </Grid>
        </DialogTitle>
        
        
        <DialogContent dividers sx={{ p: 4, borderTop:'0px' }} >        
        <form onSubmit={handleSubmit}>
        <Box >            
            <Grid container spacing={3}>
                <Grid item md container direction="column" > 
                  <Typography variant="heading2" color={'secondary'} >{t('Select Subscription')}</Typography>
                  <FormControl sx={{marginBottom: '15px', marginRight: '10px'}}>                    
                      <Select
                      
                      id="subscription"
                      value={formData.subscription}
                      name={"subscription"}
                      onChange={handleSubscriptionChange}
                      placeholder ="Select Subscription"                      
                      error={Boolean(errors.subscription)}                      
                      sx={!validateSubscriptionForm() ? {border: '1px solid red', marginBottom: '20px' } : {}}
                      >
                      <MenuItem value={t('Select Subscription')}>{t('Select Subscription')}</MenuItem>
                      <MenuItem value="NoSubscription">{t('NoSubscription')}</MenuItem>
                      <MenuItem value="Tier1">{t('Tier1')}</MenuItem>                        
                      </Select>
                      {!validateSubscriptionForm() && (<FormHelperText error sx={{marginTop:'-10px'}}>Please select a subscription</FormHelperText>)}
                    </FormControl>
                    
                </Grid>
            </Grid>
            <DialogActions>
            <Button type="submit" variant="contained" color="primary">{t('Submit')} </Button>
            </DialogActions>
          </Box>              
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
}