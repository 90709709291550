// Location List Component - To show all locations list and their details and actions to perform on location list
import React, { useContext, useEffect, useState } from "react";
import { FormControl, Grid, InputAdornment, OutlinedInput, Typography } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleParking } from '@fortawesome/pro-regular-svg-icons'
import { useTranslation } from "react-i18next";

import LocationDetails from "./location-details";
import { locationsService } from "../../services/locations-service";
import { SnackbarContext } from "../../context/snackbar-context";
import LPCustomTable from "../../ui-components/lp-custom-table/lp-custom-table";

const LocationsList = (props) => {
  // to get the translation texts
  const { t } = useTranslation("General");
  // Snackbar - success and error messages
  const { showSnackbar } = useContext(SnackbarContext);
  // state variable to store open and close dialog
  const [openDialog, setOpenDialog] = React.useState(false);
  // state variable to store location details 
  const [selectedLocation, setSelectedLocation] = useState(null);
  // state variable to store locations list
  const [locationsList, setLocationsList] = useState([]);
  // state variable to store pagination page number
  const [page, setPage] = useState(1);
  // state variable to store search param
  const [searchParam, setSearchParam] = useState(null);
  // state variable to store pagination amount per page
  const [paginationAmountPerPage, setPaginationAmountPerPage] = useState(50);
  // state variable to store and validate more records availbel for next scroll to call endpoint
  const [hasMore, setHasMore] = useState(true);
  // loading state
  const [loading, setLoading] = useState(false);
  // columns to show in table
  const columns = [
    { id: 'id', label: 'Type', minWidth: 100, align: 'center', width: 70, component: 'icon' },
    { id: 'nprId', label: 'Location', minWidth: 100, link: true },
    { id: 'address', label: 'Address', minWidth: 100 },
    { id: 'nprId', label: 'Zone Number', minWidth: 100 },
    { id: 'lastUpdated', label: 'Last Updated', minWidth: 100 }
  ]
  // state for search bar to handle search value change 
  const [searchQuery, setSearchQuery] = useState('');
  // state for total locations
  const [totalLoactions, setTotalLoactions] = useState(0);

  // useEffect to handle search bar value change by setting a timer to wait for 500ms before updating the searchLocation state to avoid unnecessary re-rendering of the locations list component 
  useEffect(() => {
    if (!searchQuery) {
      // Clear the data when search term is empty
      setLocationsList([]);
      setHasMore(false)
    }
    setLoading(true);
    const searchTimer = setTimeout(() => {
      // setLocationsList([]);
      setPage(1);
      setSearchParam(searchQuery || null)
      setHasMore(true)
    }, 500);
    // To clear the timeout when the searchQuery changes
    return () => clearTimeout(searchTimer);
  }, [searchQuery]);

  // Get all locations list from API and set to state variable locationsList will be called on page load and on search location 
  useEffect(() => {
    let locationSearchparams = {
      PaginationPage: page,
      PaginationAmountPerPage: paginationAmountPerPage,
      SearchValue: searchParam || null,
      SearchField: searchParam ? 'SearchIndex' : null
    }
    const getAllLocations = () => {
      setLoading(true);
      locationsService.getAllLocations(locationSearchparams).then((response) => {
        const newData = response.result || [];
        if (newData.length > 0) {
          newData.forEach((location) => {
            location.id = <FontAwesomeIcon icon={faCircleParking} style={{ color: location.isActive ? '#187F1B' : '#7F1818' }} size="lg" />
            location.address = [location.street, location.streetNumber, location.city].join(', ');
            location.lastUpdated = [location.lastUpdated['formattedDate'], location.lastUpdated['formattedTime']].join(' ');
          });
          if (searchParam) {
            setLocationsList(newData);
          } else {
            setLocationsList((prevData) => [...prevData, ...newData]);
          }
          // setLocationsList((prevData) => [...prevData, ...newData]);
          
          if (response.pagination.totalPages <= 1 || newData.length < paginationAmountPerPage) {
            setHasMore(false);
          } else {
            setHasMore(true);
          }
        } else {
          if (searchParam) {
            setLocationsList(newData);
          } else {
            setLocationsList((prevData) => [...prevData, ...newData]);
          }
          setHasMore(false);
        }
        setTotalLoactions(response.pagination.totalResults || 0);
        setLoading(false);

      }, (error) => {
        setLoading(false);
        console.log("Locations Error", error);
        showSnackbar(error.message.title, error.message.content, 'error');
      });
    };
      getAllLocations();
    // return () => {};
  }, [page, searchParam]);

  // update page number on scroll
  const updatePage = () => {
    setPage((prevPage) => prevPage + 1);
  }

  // on click of location row in table
  const onSelectLocation = (locationDetails) => {
    setSelectedLocation(locationDetails.uniqueId);
    setTimeout(() => {
      setOpenDialog(true);
    }, 10);
  }

  return (
    <>
      {/* <FontAwesomeIcon icon={faGarage} size="xs"/> <FontAwesomeIcon icon={faGarage} size="sm"/> <FontAwesomeIcon icon={faGarage}/> <FontAwesomeIcon icon={faGarage} size="lg"/> */}
      <Grid container
        justifyContent="space-between"
        alignItems="center" my={3} >
        <Grid item xs="auto">
          <Typography variant="heading1" color="secondary">{t('Locations')}</Typography>
        </Grid>
        <Grid item>
          <Typography variant="heading1" mr={1} color="secondary">{t('Total Amount')}</Typography>
          <Typography variant="text1" color="gray1.main" >{totalLoactions}</Typography>
        </Grid>
      </Grid>
      <Grid container color='secondary1' sx={{ display: 'flex', backgroundColor: 'secondary2.main' }}>
        <FormControl sx={{ m: 1, width: 400 }} variant="outlined" size="small" color="secondary">
          <OutlinedInput value={searchQuery}
            onChange={(event) => {
              setSearchQuery(event.target.value);
            }}
            startAdornment={<InputAdornment position="start"> <SearchIcon color="secondary" /></InputAdornment>}
            placeholder={t('Search')}
          />
        </FormControl>
      </Grid>
      <LPCustomTable columns={columns} data={locationsList} onRowClick={onSelectLocation} updatePage={updatePage} hasMore={hasMore} loading={loading} />

      {selectedLocation && <LocationDetails openDialog={openDialog} setOpenDialog={setOpenDialog} selectedLocation={selectedLocation} setSelectedLocation={setSelectedLocation} />}
    </>
  );
}

export default LocationsList;
